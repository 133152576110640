import { Box, Button, Card, CardContent, Grid, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberActions } from "../store/actions";
import Layout from "./Layout";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default function WinningNum() {
    const [tabValue, setTabValue] = useState("2d");

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }
    const dispatch = useDispatch();

    const { round2d, round3d } = useSelector(state => state.numberManagement);

    useEffect(() => {
        dispatch(numberActions.get2dRound());
        dispatch(numberActions.get3dRound());
    }, []);

    const [ has2dRound, setHas2dRound ] = useState(false);
    const [ has3dRound, setHas3dRound ] = useState(false);

    const date2dRef = useRef();
    const time2dRef = useRef();
    const date3dRef = useRef();
    const time3dRef = useRef();

    useEffect(() => {
        if (round2d?.status === 0) {
            setHas2dRound(true);
        } else {
            setHas2dRound(false);
        }
        if (round3d?.status === 0) {
            setHas3dRound(true);
        } else {
            setHas3dRound(false);
        }
    }, [round2d, round3d]);

    function roundName() {
        const year = new Date().getFullYear()+"";
        const month = new Date().getMonth();
        const date = new Date().getDate();
        const hour = new Date().getHours();
        const minutes = new Date().getMinutes();
        return ""+year+(month<10?"0":"")+(month+1)+date+(hour<12?"AM":(hour==12?(minutes>=0? "PM": "AM"): "PM"));
    }

    function roundDate() {
        let date = new Date(new Date().getTime() - ((-390) * 60 * 1000));
        return date.toISOString().split("T")[0];
        const year = new Date().getFullYear()+"";
        const month = new Date().getMonth();
        // const date = new Date().getDate();
        return date+" "+monthNames[month]+" "+year;
    }

    function roundWonTime2d() {
        const hour = new Date().getHours();
        const minutes = new Date().getMinutes();

        const morning = new Date().setHours(12, 0, 0, 0);
        const evening = new Date().setHours(16, 30, 0, 0);
        
        let date = new Date((hour<12? morning :(hour==12?(minutes>=0? evening: morning): evening)) - ((-390) * 60 * 1000));
        let time = date.toISOString().split("T")[1].split(".")[0].split(":");
        time.pop();
        return time.join(":");
        return (hour<12?"12:00PM":(hour==12?(minutes>=0? "4:30PM": "12:00PM"): "4:30PM"));
    }

    function roundName3d() {
        const year = new Date().getFullYear()+"";
        const month = new Date().getMonth();
        const date = new Date().getDate();
        return ""+year+(month<10?"0":"")+(month+1)+date;
    }

    function timeFormatter(time) {
        if (time.includes("AM")) {
            return time.replace("AM", "");
        }
        if (time.includes("PM")) {
            let replaced = time.replace("PM", "");
            let splitted = replaced.split(":");
            splitted[0] = parseInt(splitted[0])+12;
            return splitted.join(":");
        }
    }

    const open2dRoundHandler = () => {
        const roundname = roundName();
        const year = new Date().getFullYear()+"";
        const month = new Date().getMonth();
        const date = new Date().getDate();
        const hour = new Date().getHours();
        const minutes = new Date().getMinutes();
        
        const morning = new Date().setHours(12, 0, 0, 0);
        const evening = new Date().setHours(16, 30, 0, 0);
        
        // let winningdate = Date.parse(year+"-"+(month<9? "0": "")+(month+1)+"-"+(date<10?"0":"")+date+"T"+(hour<12?"12:00:00":(hour==12?(minutes>=0? "4:30:00": "12:00:00"): "4:30:00"))+".000+06:30");
        // let winningdate = Date.parse(hour<12? new Date(morning).toISOString() :(hour==12?(minutes>=0? new Date(evening).toISOString(): new Date(morning).toISOString()): new Date(evening).toISOString()))

        let winningdate = Date.parse(new Date(date2dRef.current.value+" "+time2dRef.current.value).toISOString());
        dispatch(numberActions.create2DRound(roundname, winningdate));
    }

    const open3dRoundHandler = () => {
        const roundname = roundName3d();
        const year = new Date().getFullYear()+"";
        const month = new Date().getMonth();
        const date = new Date().getDate();
        
        // let winningdate = Date.parse(new Date(new Date().setHours(15, 0, 0, 0)).toISOString());

        let winningdate = Date.parse(new Date(date3dRef.current.value+" "+time3dRef.current.value).toISOString());
        dispatch(numberActions.create3DRound(roundname, winningdate));
    }

    const [winNumber2d, setWinNumber2d] = useState("");
    const [winNumber3d, setWinNumber3d] = useState("");
    
    const winNumber2dRef = useRef();
    const showTime2dRef = useRef();
    const winNumber3dRef = useRef();
    const showTime3dRef = useRef();

    const close2dRoundHandler = () => {
        if (winNumber2d.trim() === "" || showTime2dRef.current.value.trim() === "") return;
        if (winNumber2d.trim() !== "" && winNumber2d.length === 2) {
            dispatch(numberActions.set2dWinNumber(winNumber2d, showTime2dRef.current.value));
            setWinNumber2d("");
            winNumber2dRef.current.value = "";
            showTime2dRef.current.value = "";
        }
    }

    const close3dRoundHandler = () => {
        if (winNumber3d.trim() === "" || showTime3dRef.current.value.trim() === "") return;
        if (winNumber3d.trim() !== "" && winNumber3d.length === 3) {
            dispatch(numberActions.set3dWinNumber(winNumber3d, showTime3dRef.current.value));
            setWinNumber3d("");
            winNumber3dRef.current.value = "";
            showTime3dRef.current.value = "";
        }
    }

    return (
        <Layout>
            <Typography variant="h5">Winning Number</Typography>
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="2D Winning Number" value="2d" id="2d" sx={{ mr: "2rem" }} />
                <Tab label="3D Winning Number" value="3d" id="3d" />
            </Tabs>
            <TabPanel value={tabValue} index="2d">
                <Card sx={{ mt: "3rem" }}>
                    <CardContent sx={{ width: { sm: "40%" }, margin: "auto" }}>
                        {has2dRound? 
                            (<StaticFormInputField label="Round Name" id="roundName" value={round2d?.roundname}/>):
                            (<FormInputField disabled label="Round Name" id="roundName" value={roundName()}/>)
                        }
                        {/* <FormInputField disabled label="Round Name" id="roundName" value={has2dRound? round2d?.roundname: roundName()}/> */}
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                {has2dRound?
                                    (<StaticFormInputField type="date" label="Round Winning Date" id="roundWinDate" value={has2dRound? round2d?.date: roundDate()}/>):
                                    (<FormInputField disabled={has2dRound} inputRef={date2dRef} type="date" label="Round Winning Date" id="roundWinDate" value={has2dRound? round2d?.date: roundDate()}/>)
                                }
                                {/* <FormInputField disabled={has2dRound} inputRef={date2dRef} type="date" label="Round Winning Date" id="roundWinDate" value={has2dRound? round2d?.date: roundDate()}/> */}
                            </Grid>
                            <Grid item sm={6}>
                                {has2dRound?
                                    (<StaticFormInputField type="time" label="Round Winning Time" id="roundWinTime" value={has2dRound? round2d?.time: roundWonTime2d()}/>):
                                    (<FormInputField disabled={has2dRound} inputRef={time2dRef} type="time" label="Round Winning Time" id="roundWinTime" value={has2dRound? round2d?.time: roundWonTime2d()}/>)
                                }
                                {/* <FormInputField disabled={has2dRound} inputRef={time2dRef} type="time" label="Round Winning Time" id="roundWinTime" value={has2dRound? round2d?.time: roundWonTime2d()}/> */}
                            </Grid>
                        </Grid>
                        <FormInputField inputRef={winNumber2dRef} disabled={!has2dRound} label="Winning Number" id="winNumber" onChange={(e) => setWinNumber2d(e.target.value)}/>
                        {has2dRound && (<FormInputField inputRef={showTime2dRef} disabled={!has2dRound} label="Show Time" id="showTime"/>)}
                        <Grid container spacing={2} sx={{ mt: "1rem" }}>
                            <Grid item sm={6}>
                                <Button size='large' fullWidth variant="contained"
                                sx={{
                                    backgroundColor: "#F5F5F5",
                                    color: "rgba(0,0,0,0.4)",
                                    fontWeight: "600",
                                    "&:hover": {
                                        boxShadow: "none",
                                        backgroundColor: "#F5F5F5"
                                    }
                                }}>
                                    Cancel
                                </Button>
                            </Grid>
                            {has2dRound 
                            ?(<Grid item sm={6}>
                                <Button size='large' fullWidth variant="contained"
                                onClick={close2dRoundHandler}
                                sx={{
                                    fontWeight: "600"
                                }}>
                                    Close Round
                                </Button>
                            </Grid>)
                            :(<Grid item sm={6}>
                                <Button size='large' fullWidth variant="contained"
                                onClick={open2dRoundHandler}
                                sx={{
                                    fontWeight: "600"
                                }}>
                                    Open Round
                                </Button>
                            </Grid>)}
                        </Grid>
                    </CardContent>
                </Card>
            </TabPanel>
            <TabPanel value={tabValue} index="3d">
                <Card sx={{ mt: "3rem" }}>
                    <CardContent sx={{ width: { sm: "40%" }, margin: "auto" }}>
                        {has3dRound?
                            (<StaticFormInputField label="Round Name" id="roundName" value={has3dRound? round3d?.roundname: roundName3d()}/>):
                            (<FormInputField disabled label="Round Name" id="roundName" value={has3dRound? round3d?.roundname: roundName3d()}/>)
                        }
                    {/* <FormInputField disabled label="Round Name" id="roundName" value={has3dRound? round3d?.roundname: roundName3d()}/> */}
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                {has3dRound?
                                    (<StaticFormInputField type="date" label="Round Winning Date" id="roundWinDate" value={has3dRound? round3d?.date: roundDate()} />):
                                    (<FormInputField disabled={has3dRound} inputRef={date3dRef} type="date" label="Round Winning Date" id="roundWinDate" value={has3dRound? round3d?.date: roundDate()} />)
                                }
                                {/* <FormInputField disabled={has3dRound} type="date" label="Round Winning Date" id="roundWinDate" value={has3dRound? round3d?.date: roundDate()} /> */}
                            </Grid>
                            <Grid item sm={6}>
                                {has3dRound?
                                    (<StaticFormInputField type="time" label="Round Winning Time" id="roundWinTime" value={has3dRound? round3d?.time: "15:00"} />):
                                    (<FormInputField disabled={has3dRound} inputRef={time3dRef} type="time" label="Round Winning Time" id="roundWinTime" value={has3dRound? round3d?.time: "15:00"} />)
                                }
                                {/* <FormInputField disabled={has3dRound} type="time" label="Round Winning Time" id="roundWinTime" value={has3dRound? round3d?.time: "15:00"} /> */}
                            </Grid>
                        </Grid>
                        <FormInputField inputRef={winNumber3dRef} disabled={!has3dRound} label="Winning Number" id="winNumber" onChange={(e) => setWinNumber3d(e.target.value)} />
                        {has3dRound && (<FormInputField inputRef={showTime3dRef} disabled={!has3dRound} label="Show Time" id="showTime"/>)}
                        <Grid container spacing={2} sx={{ mt: "1rem" }}>
                            <Grid item sm={6}>
                                <Button size='large' fullWidth variant="contained"
                                sx={{
                                    backgroundColor: "#F5F5F5",
                                    color: "rgba(0,0,0,0.4)",
                                    fontWeight: "600",
                                    "&:hover": {
                                        boxShadow: "none",
                                        backgroundColor: "#F5F5F5"
                                    }
                                }}>
                                    Cancel
                                </Button>
                            </Grid>
                            {has3dRound
                            ?(<Grid item sm={6}>
                                <Button size='large' fullWidth variant="contained"
                                onClick={close3dRoundHandler}
                                sx={{
                                    fontWeight: "600"
                                }}>
                                    Close Round
                                </Button>
                            </Grid>)
                            :(<Grid item sm={6}>
                                <Button size='large' fullWidth variant="contained"
                                onClick={open3dRoundHandler}
                                sx={{
                                    fontWeight: "600"
                                }}>
                                    Open Round
                                </Button>
                            </Grid>)
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </TabPanel>
        </Layout>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
}

const FormInputField = ({label, id, type='text', disabled=false, value, onChange, inputRef}) => {
    return (
        <Box sx={{ mt: '1rem' }}>
            <InputLabel htmlFor={id} sx={{ color: "#222", mb: '1rem' }}>{label}</InputLabel>
            <TextField
            id={id}
            disabled={disabled}
            defaultValue={value}
            inputRef={inputRef}
            type={type}
            onChange={onChange}
            fullWidth
            placeholder={label}
            sx={{
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&:hover fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&.Mui-focused fieldset": {
                        borderWidth: "1px",
                        borderColor: "rgba(0,0,0,0.4)"
                    }
                }
            }}
            />
        </Box>
    )
}

const StaticFormInputField = ({label, id, type='text', value, onChange}) => {
    return (
        <Box sx={{ mt: '1rem' }}>
            <InputLabel htmlFor={id} sx={{ color: "#222", mb: '1rem' }}>{label}</InputLabel>
            <TextField
            id={id}
            disabled
            value={value}
            type={type}
            onChange={onChange}
            fullWidth
            placeholder={label}
            sx={{
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&:hover fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&.Mui-focused fieldset": {
                        borderWidth: "1px",
                        borderColor: "rgba(0,0,0,0.4)"
                    }
                }
            }}
            />
        </Box>
    )
}

function FormSelectInput({label, id, items}) {
    return (
        <Box sx={{ mt: '1rem' }}>
            <InputLabel sx={{ color: "#222", mb: '1rem' }}>{label}</InputLabel>
            <Select
            id={id}
            fullWidth
            value={items[0]}
            sx={{
                "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&:hover fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&.Mui-focused fieldset": {
                        borderWidth: "1px",
                        borderColor: "rgba(0,0,0,0.4)"
                    }
                }
            }}
            >
                {items.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                ))}
            </Select>
        </Box>
    )
}