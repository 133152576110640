import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField } from "@mui/material";
import { CloseRounded, MoreHoriz, Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agentActions, numberActions, statusActions, userActions } from "../store/actions";
import { useLocation, useNavigate } from "react-router-dom";

export default function StyledTable({
    headers,
    data,
    hasDetail=false, 
    detailType,
    hasAction=false,
    hasBreakNumberAction=false,
    sx,
    tableHeadsx,
    tableCellsx,
    isTransparentHeader=true,
    labelSort,
    order,
    orderBy,
    onRequestSort
}) {

    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const open = Boolean(anchorElMenu);
    const navigate = useNavigate();

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
      };

    const handleMenuClick = (event) => {
        setAnchorElMenu(event.currentTarget);
    }
    
    const handleMenuClose = () => {
        setAnchorElMenu(null);
    }

    // For breaknumber edit
    const [smallAnchorElMenu, setSmallAnchorElMenu] = useState(null);
    const smallOpen = Boolean(smallAnchorElMenu);

    const handleSmallMenuClick = (event) => {
        setSmallAnchorElMenu(event.currentTarget);
    }
    
    const handleSmallMenuClose = () => {
        setSmallAnchorElMenu(null);
    }

    const handleBetDetailNavigate = (data) => {
        navigate("/bet-report/"+detailType+"/"+data.roundid+"/"+data.createdby, {
            state: {
                roundname: data.roundname,
                playername: data.playername,
            }
        });
    }
    
    return (
        <TableContainer component={Paper} sx={{ ...sx, boxShadow: "none !important" }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {headers.map(({key, title, sx={}}) => (
                            <TableCell key={key} 
                            sx={{
                                ...tableHeadsx,
                                backgroundColor: isTransparentHeader && "transparent",
                                fontWeight: "500",
                                display: (title==='hidden')? 'none': 'table-cell',
                                ...sx
                            }}>
                                {labelSort?
                                    key === 'name' || key === 'createdat' 
                                    ?(<TableSortLabel
                                        active={orderBy===key}
                                        direction={orderBy===key? order: 'asc'}
                                        onClick={createSortHandler(key)}
                                    >
                                        {title}
                                    </TableSortLabel>
                                    )
                                    :title
                                :title
                                }
                            </TableCell>
                        ))}
                        {(hasAction || hasDetail) && (
                            <TableCell key='action' 
                            sx={{
                                ...tableHeadsx,
                                backgroundColor: isTransparentHeader && "transparent",
                                fontWeight: "bold",
                                padding: "15px 15px 0 15px"
                            }}>
                                
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(data && data.length > 0) ? data.map((item, index) => (
                        <TableRow key={index}>
                            {headers.map(({key, title, sx={}}) => (
                                <TableCell sx={{
                                    padding: "15px",
                                    display: (title==='hidden')? 'none': 'table-cell',
                                    ...sx
                                }} key={key}>
                                    {item[key]}
                                </TableCell>
                            ))}
                            {hasAction && (
                                <TableCell
                                align="center"
                                sx={{
                                    padding: "13px"
                                }}
                                key='action'
                                >
                                    <Button
                                    sx={{
                                        padding: "3px",
                                        color: "rgba(102,102,102)",
                                        minWidth: "auto",
                                        backgroundColor: "transparent",
                                        border: "0.8px solid rgba(0,0,0,0.2)",
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                            border: "0.8px solid rgba(0,0,0,0.2)",
                                        }
                                    }}
                                    id={item[headers.filter((obj) => obj?.title === "hidden")[0]?.key]}
                                    data={JSON.stringify(item)}
                                    active={item[headers.filter((obj) => obj?.key === "status")[0]?.key]}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleMenuClick}
                                    >
                                        <MoreHoriz fontSize="small"/>
                                    </Button>
                                    <DropdownMenu
                                    anchorEl={anchorElMenu}
                                    open={open}
                                    onClose={handleMenuClose}
                                    />
                                </TableCell>
                            )}
                            {hasBreakNumberAction && (
                                <TableCell
                                align="center"
                                sx={{
                                    padding: "13px"
                                }}
                                key='action'
                                >
                                    <Button
                                    sx={{
                                        padding: "3px",
                                        color: "rgba(102,102,102)",
                                        minWidth: "auto",
                                        backgroundColor: "transparent",
                                        border: "0.8px solid rgba(0,0,0,0.2)",
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                            border: "0.8px solid rgba(0,0,0,0.2)",
                                        }
                                    }}
                                    id={item[headers.filter((obj) => obj?.title === "hidden")[0]?.key]}
                                    data={JSON.stringify(item)}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleSmallMenuClick}
                                    >
                                        <MoreHoriz fontSize="small"/>
                                    </Button>
                                    <SmallDropDownMenu
                                    anchorEl={smallAnchorElMenu}
                                    open={smallOpen}
                                    onClose={handleSmallMenuClose}
                                    />
                                </TableCell>
                            )}
                            {hasDetail && (
                                <TableCell
                                    align="center"
                                    sx={{
                                        padding: "13px"
                                    }}
                                    key='action'
                                >
                                    <Button
                                    sx={{
                                        padding: "3px",
                                        color: "rgba(102,102,102)",
                                        minWidth: "auto",
                                        backgroundColor: "transparent",
                                        border: "0.8px solid rgba(0,0,0,0.2)",
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                            border: "0.8px solid rgba(0,0,0,0.2)",
                                        }
                                    }}
                                    // data={JSON.stringify(item)}
                                    onClick={() => handleBetDetailNavigate(item)}
                                    variant="outlined"
                                    size="small"
                                    >
                                        Detail
                                    </Button>
                                </TableCell>
                            )}
                        </TableRow>
                    )):
                    <TableRow>
                        <TableCell colSpan={headers.length + 1} sx={{ textAlign: 'center' }}>No data found</TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function DropdownMenu({anchorEl, open, onClose}) {

    const location = useLocation();
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth.agent);

    const toggleActivationHandler = async () => {
        let status = JSON.parse(anchorEl.getAttribute("data")).status === "Activated"? 0: 1;
        if (location.pathname.includes("player")) {
            await dispatch(agentActions.updatePlayerData({playerid: anchorEl.getAttribute('id'), status}))
        } else {
            await dispatch(agentActions.toggleActivation(anchorEl.getAttribute('id')));
        }
        onClose();
    }

    const navigateHandler = () => {
        navigate(anchorEl.getAttribute("id"));
    }

    // add unit model
    const [addUnitModelOpen, setAddUnitModelOpen] = useState(false);

    const [unitState, setUnitState] = useState(null);

    const unitAmountRef = useRef();
    const commissionRef = useRef();

    const handleCloseAddUnitModel = () => {
        setAddUnitModelOpen(false);
    }

    const handleOpenAddUnitModel = () => {
        setUnitState("topup");
        setAddUnitModelOpen(true);
    }

    const handleOpenWithdrawUnitModel = () => {
        setUnitState("withdraw");
        setAddUnitModelOpen(true);
    }

    const [unit, setUnit] = useState(0);

    const handleUnitChange = () => {
        if (unitState === "topup") {
            setUnit(parseInt(unitAmountRef.current.value) + parseInt(commissionRef.current.value));
        } else {
            setUnit(parseInt(unitAmountRef.current.value));
        }
    }

    const addUnitHandler = async () => {
        if (unit > 0) {
            if (unitState === "withdraw" && unit > JSON.parse(anchorEl?.getAttribute('data')??"{}").unitbalance) {
                dispatch(statusActions.setSuccess(false));
                dispatch(statusActions.setMessage("Insufficient unit amount to withdraw"));
                return;
            }

            if (auth.agenttype!=="Admin" && unitState === "topup" && unit > auth.unitbalance) {
                dispatch(statusActions.setSuccess(false));
                dispatch(statusActions.setMessage("Insufficient unit amount to topup"));
                return;
            }

            let unitbalance = Math.abs(unit);
            if (unitState === "withdraw") {
                unitbalance = Math.abs(unit)*-1;
            }

            if (location.pathname.includes("player")) {
                dispatch(agentActions.updatePlayerData({playerid: anchorEl.getAttribute("id"), unitbalance, commission: commissionRef?.current?.value}));
            } else if (location.pathname.includes("masteragents")) {
                dispatch(agentActions.updateMasterAgentApi({agentid : anchorEl.getAttribute("id"), unitbalance, commission: commissionRef?.current?.value}));
            } else {
                dispatch(agentActions.updateAgentApi({agentid : anchorEl.getAttribute("id"), unitbalance, commission: commissionRef?.current?.value}));
            }
            handleCloseAddUnitModel();
            onClose();
        } else {
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage("Please enter unit"));
        }
    }

    // reset password model
    const [resetPasswordModelOpen, setResetPasswordModelOpen] = useState(false);

    const handleCloseResetPasswordModel = () => {
        setResetPasswordModelOpen(false);
    }

    const handleOpenResetPasswordModel = () => {
        setResetPasswordModelOpen(true);
    }

    // show password
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const resetPasswordHandler = async () => {
        if (password.trim() !== "") {
            if (location.pathname.includes("player")) {
                await dispatch(agentActions.updatePlayerData({playerid: anchorEl.getAttribute("id"), password}));
            } else {
                await dispatch(agentActions.updateMasterAgentApi({agentid : anchorEl.getAttribute("id"), password}));
            }
            handleShowPassword();
            handleCloseResetPasswordModel();
            onClose();
        }
    }

    return (
        <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right"
        }}
        PaperProps={{
            sx: {
                overflow: 'visible',
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 10,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            }
        }}
        sx={{
            mt: "10px",
            "& .MuiPaper-root": {
                minWidth: "200px",
                borderRadius: "5px",
                filter: "drop-shadow(rgba(0, 0, 0, 0.05) 0px 2px 3px)"
            },
            "& ul": {
                padding: "6px",
            }
        }}
        >
            <MenuItem onClick={navigateHandler}>View Detail</MenuItem>
            <Divider/>
            <MenuItem onClick={handleOpenAddUnitModel}>Topup Unit</MenuItem>
            <MenuItem onClick={handleOpenWithdrawUnitModel}>Withdraw Unit</MenuItem>
            <MenuItem onClick={handleOpenResetPasswordModel}>Reset Password</MenuItem>
            <Divider/>
            <MenuItem disabled={(open && anchorEl.getAttribute("active")==="Activated")?true: false} onClick={toggleActivationHandler}>Activate</MenuItem>
            <MenuItem disabled={(open && anchorEl.getAttribute("active")==="Activated")?false: true} onClick={toggleActivationHandler}>Deactivate</MenuItem>

            {/* Password Reset Modal */}
            <Dialog
            open={resetPasswordModelOpen}
            fullWidth
            maxWidth="xs"
            onClose={handleCloseResetPasswordModel}
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    padding: "16px 90px",
                    fontSize: "1.6rem",
                }}>
                    Reset Password
                    <IconButton onClick={handleCloseResetPasswordModel} size="small" sx={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        border: "1px solid rgba(0,0,0,0.4)"
                    }}>
                        <CloseRounded fontSize="small"/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{
                    padding: "16px 90px"
                }}>
                    <Divider/>
                    <FormInputField label="New Password" id="password" 
                    type={showPassword? 'text': 'password'}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                onClick={handleShowPassword}>
                                    {showPassword? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}/>
                </DialogContent>
                <DialogActions
                    disableSpacing
                    sx={{
                        padding: "16px 90px",
                        flexDirection: "column",
                    }}
                >
                    <Button size='large' fullWidth variant="contained"
                    onClick={resetPasswordHandler}
                    sx={{
                        fontWeight: "600"
                    }}>
                        Reset
                    </Button>
                    <Button onClick={handleCloseResetPasswordModel} size='large' fullWidth variant="contained"
                    sx={{
                        mt: "1rem",
                        backgroundColor: "#F5F5F5",
                        color: "rgba(0,0,0,0.4)",
                        fontWeight: "600",
                        "&:hover": {
                            boxShadow: "none",
                            backgroundColor: "#F5F5F5"
                        }
                    }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Add Unit Modal */}
            <Dialog
            open={addUnitModelOpen}
            fullWidth
            maxWidth="xs"
            onClose={handleCloseAddUnitModel}
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    padding: "16px 90px",
                    fontSize: "1.6rem",
                }}>
                    {unitState === "topup"? "Topup Unit": "Withdraw Unit"}
                    <IconButton onClick={handleCloseAddUnitModel} size="small" sx={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        border: "1px solid rgba(0,0,0,0.4)"
                    }}>
                        <CloseRounded fontSize="small"/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{
                    padding: "16px 90px"
                }}>
                    <Divider/>
                    <FormInputField value={JSON.parse(anchorEl?.getAttribute('data')??"{}").phoneno} disabled label="Phone Number" id="phoneno"/>
                    <FormInputField value={JSON.parse(anchorEl?.getAttribute('data')??"{}").username} disabled label="Username" id="username"/>
                    <FormInputField inputRef={unitAmountRef} defaultValue={0} onChange={handleUnitChange} type="number" label="Unit Amount" id="unit"/>
                    {unitState === "topup" && 
                        (<FormInputField inputRef={commissionRef} defaultValue={0} onChange={handleUnitChange} type="number" label="Commission" id="commission"/>)
                    }
                </DialogContent>
                <DialogActions
                    disableSpacing
                    sx={{
                        padding: "16px 90px",
                        flexDirection: "column",
                    }}
                >
                    <Button size='large' fullWidth variant="contained"
                    onClick={addUnitHandler}
                    sx={{
                        fontWeight: "600"
                    }}>
                        Save
                    </Button>
                    <Button onClick={handleCloseAddUnitModel} size='large' fullWidth variant="contained"
                    sx={{
                        mt: "1rem",
                        backgroundColor: "#F5F5F5",
                        color: "rgba(0,0,0,0.4)",
                        fontWeight: "600",
                        "&:hover": {
                            boxShadow: "none",
                            backgroundColor: "#F5F5F5"
                        }
                    }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Menu>
    )
}

function SmallDropDownMenu({anchorEl, open, onClose}) {
    
    const dispatch = useDispatch();

    // add unit model
    const [addUnitModelOpen, setAddUnitModelOpen] = useState(false);

    const handleCloseAddUnitModel = () => {
        setAddUnitModelOpen(false);
    }

    const handleOpenAddUnitModel = () => {
        setAddUnitModelOpen(true);
    }

    const [breakAmount, setBreakAmount] = useState(0);

    const handleChangeBreakAmount = (e) => {
        setBreakAmount(e.target.value);
    }

    useEffect(() => {
        setBreakAmount(JSON.parse(anchorEl?.getAttribute('data')??"{}")?.breakamount);
    }, [anchorEl]);

    const editUnitHandler = async () => {
        if (breakAmount !== 0) {
            if (breakAmount < 0 && Math.abs(breakAmount) > JSON.parse(anchorEl?.getAttribute('data')??"{}").breakamount) {
                return;
            }
            let breaknumber = JSON.parse(anchorEl?.getAttribute('data')??"{}")?.breaknumber+"";
            
            if (breaknumber.trim().length === 2) {
                await dispatch(numberActions.create2DBreakNumber(breaknumber, breakAmount));
            }

            if (breaknumber.trim().length === 3) {
                await dispatch(numberActions.create3DBreakNumber(breaknumber, breakAmount));
            }
            setBreakAmount(0);
            handleCloseAddUnitModel();
            onClose();
        }
    }

    return (
        <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right"
        }}
        PaperProps={{
            sx: {
                overflow: 'visible',
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 10,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            }
        }}
        sx={{
            mt: "10px",
            "& .MuiPaper-root": {
                minWidth: "200px",
                borderRadius: "5px",
                filter: "drop-shadow(rgba(0, 0, 0, 0.05) 0px 2px 3px)"
            },
            "& ul": {
                padding: "6px",
            }
        }}
        >
            <MenuItem onClick={handleOpenAddUnitModel}>Edit Break Unit</MenuItem>

            {/* Add Unit Modal */}
            <Dialog
            open={addUnitModelOpen}
            fullWidth
            maxWidth="xs"
            onClose={handleCloseAddUnitModel}
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    padding: "16px 90px",
                    fontSize: "1.6rem",
                }}>
                    Break Number
                    <IconButton onClick={handleCloseAddUnitModel} size="small" sx={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        border: "1px solid rgba(0,0,0,0.4)"
                    }}>
                        <CloseRounded fontSize="small"/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{
                    padding: "16px 90px"
                }}>
                    <Divider/>
                    <FormInputField value={JSON.parse(anchorEl?.getAttribute('data')??"{}").breaknumber} disabled label="Number" id="number"/>
                    <FormInputField value={JSON.parse(anchorEl?.getAttribute('data')??"{}").breakamount} onChange={handleChangeBreakAmount} label="Break Amount" id="breakamount"/>
                </DialogContent>
                <DialogActions
                    disableSpacing
                    sx={{
                        padding: "16px 90px",
                        flexDirection: "column",
                    }}
                >
                    <Button size='large' fullWidth variant="contained"
                    onClick={editUnitHandler}
                    sx={{
                        fontWeight: "600"
                    }}>
                        Add
                    </Button>
                    <Button onClick={handleCloseAddUnitModel} size='large' fullWidth variant="contained"
                    sx={{
                        mt: "1rem",
                        backgroundColor: "#F5F5F5",
                        color: "rgba(0,0,0,0.4)",
                        fontWeight: "600",
                        "&:hover": {
                            boxShadow: "none",
                            backgroundColor: "#F5F5F5"
                        }
                    }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Menu>
    )
}

const FormInputField = ({label, id, InputProps, type='text', onChange, inputRef, disabled=false, value}) => {
    return (
        <Box sx={{ mt: '1rem' }}>
            <InputLabel htmlFor={id} sx={{ color: "#222", mb: '1rem' }}>{label}</InputLabel>
            <TextField
            disabled={disabled}
            onChange={onChange}
            defaultValue={value}
            inputRef={inputRef}
            id={id}
            type={type}
            fullWidth
            size="small"
            placeholder={label}
            InputProps={InputProps}
            sx={{
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&:hover fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&.Mui-focused fieldset": {
                        borderWidth: "1px",
                        borderColor: "rgba(0,0,0,0.4)"
                    }
                }
            }}
            />
        </Box>
    )
}