import { Box, Button, Grid, Menu, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import CustomPagination from "../../components/CustomPagination";
import MyDateRangePicker from "../../components/MyDateRangePicker";
import StyledTable from "../../components/StyledTable";
import { agentActions } from "../../store/actions";
import { formatEndDateForApi, formatStartDateForApi } from "../../utils/misc";
import Layout from "../Layout";

export default function MasterAgentDetailReport() {
    const headers = [
        {
            key: "name",
            title: "Name",
        },
        {
            key: "phoneno",
            title: "Phone Number"
        },
        {
            key: "agenttype",
            title: "Agent Type"
        },
        {
            key: "createdat",
            title: "Created Date"
        },
        {
            key: "updatedat",
            title: "Updated Date"
        }
    ];

    const {masterAgents, currentMasterAgentCount} = useSelector(state => state.agentManagement);

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('createdat');
    const [datepickerAnchorEl, setDatePickerAnchorEl] = useState(null);
    const open = Boolean(datepickerAnchorEl);
    const [value, setValue] = useState({});

    const fetchData = () => {
        if (value.startDate && value.endDate) {
            dispatch(agentActions.getAllMasterAgents({page, startDate: formatStartDateForApi(value?.startDate), endDate: formatEndDateForApi(value?.endDate), order, orderBy}));
        } else {
            dispatch(agentActions.getAllMasterAgents({page, order, orderBy}));
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy===property && order==='asc';
        setOrder(isAsc? 'desc': 'asc');
        setOrderBy(property);
    }

    const handlePageChange = (e, value) => {
        setPage(value);
    }

    const toggleDatePicker = (e) => {
        if (datepickerAnchorEl !== null) {
            setDatePickerAnchorEl(null);
        } else {
            setDatePickerAnchorEl(e.currentTarget)
        }
    }

    useEffect(() => {
        fetchData();
    }, [page, value, order, orderBy])

    return (
        <Layout>
            <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography variant="h5" sx={{ fontWeight: "500" }}>Master Agent Detail Report</Typography>
                </Grid>
                <Grid item>
                    <Button onClick={toggleDatePicker} variant="contained" color="primary">Filter by date</Button>
                </Grid>
            </Grid>
            <Menu
                anchorEl={datepickerAnchorEl}
                open={open}
                // onClose={toggleDatePicker}
                sx={{
                    mt: "10px",
                    "& .MuiPaper-root": {
                        minWidth: "0px",
                        minHeight: "0px"
                    },
                    "& ul": {
                        padding: 0,
                    }
                }}
            >
                <MyDateRangePicker open={open} toggle={toggleDatePicker} value={value} setValue={setValue} />
            </Menu>
            {(value.startDate && value.endDate) && (
                <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography>{"From "+new Date(value?.startDate).toLocaleDateString() +" To "+new Date(value?.endDate).toLocaleDateString()}</Typography>
                </Grid>
                <Grid item>
                    <Button onClick={() => setValue({})} variant="contained" color="primary">Clear</Button>
                </Grid>
            </Grid>
            )}
            <StyledTable
            sx={{mt: '2rem', backgroundColor: "transparent"}}
            headers={headers}
            data={masterAgents}
            labelSort={true}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            />
            <CustomPagination
            handlePageChange={handlePageChange}
            page={page}
            perPage={10}
            dataCount={currentMasterAgentCount}
            />
        </Layout>
    );
}