import { Button, Card,  Dialog, DialogActions, DialogContent, DialogTitle, Divider, CardContent, Grid, Tab, Tabs, Typography, IconButton, InputLabel, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import StyledTable from "../components/StyledTable";
import Layout from "./Layout";
import mockData from '../mocks/index.json';
import { numberActions } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { CloseRounded } from "@mui/icons-material";

const total2dNumbers = 100;
const perRow2dNumbers = 5;

const total3dNumbers = 100;
const perRow3dNumbers = 5;

const prefix3d = 10;

const headers = [
    {
        "key": "breaknumberid",
        "title": "hidden",
    },
    {
        "key": "breaknumber",
        "title": "Number"
    },
    {
        "key": "breakamount",
        "title": "Break Amount"
    }
];


export default function Dashboard2() {
    const [tabValue, setTabValue] = useState("2d");

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const [selectedPrefix, setSelectedPrefix] = useState(null);

    const handleChangePrefix = (prefix) => {
        setSelectedPrefix(prefix)
    }

    const [breakTabValue, setBreakTabValue] = useState("2d");

    const handleBreakTabChange = (event, newValue) => {
        setBreakTabValue(newValue);
    }

    const dispatch = useDispatch();

    const {betNumbers2d, betNumbers3d, winNum2d, winNum3d} = useSelector(state => state.numberManagement);

    useEffect(() => {
        dispatch(numberActions.get2dBetNumbers());
        dispatch(numberActions.get3dBetNumbers());
        dispatch(numberActions.get2dWinNumbers());
        dispatch(numberActions.get3dWinNumbers());
    }, []);

    const created2dData=[];
    let perRow2d = [];
    
    for(let i=0; i<total2dNumbers; i++) {
        if (i!==0 && i%perRow2dNumbers===0) {
            created2dData.push(<Grid key={i/perRow2dNumbers} container sx={{ mt: "1rem" }} spacing={2}>
                {perRow2d}
            </Grid>);
            perRow2d =[]
        }
        let number = ((i<10)?"0":"")+i;
        let betNumber = betNumbers2d.find(bet => bet.number === number);
        perRow2d.push(<Grid key={i} item sm={2.4}>
            <Card sx={{backgroundColor: "transparent", border: "none"}}>
                <CardContent sx={{backgroundColor: "rgba(241, 241, 241, 1)"}}>
                    <Typography sx={{ fontSize: "1.5rem", textAlign: "center"}} color="secondary">{number}</Typography>
                    <Typography sx={{ mt: "0.5rem", textAlign: "center" }}>{betNumber? betNumber.amount: 0}</Typography>
                </CardContent>
            </Card>
        </Grid>);
        if (i===total2dNumbers-1) {
            created2dData.push(<Grid key={i/perRow2dNumbers} container sx={{ mt: "1rem" }} spacing={2}>
                {perRow2d}
            </Grid>);
            perRow2d =[]
        }
    }

    const prefix3dData = [];
    for (let i=0; i<prefix3d; i++) {
        prefix3dData.push(<Grid item key={i}>
            <Card onClick={() => handleChangePrefix(i)} sx={{backgroundColor: "transparent", border: "none", cursor: "pointer"}}>
                <CardContent sx={{backgroundColor: (selectedPrefix===i)?"#7A0025":"rgba(241, 241, 241, 1)", padding: "10px !important"}}>
                    <Typography sx={{ color: (selectedPrefix===i)? "#fff": "#000", fontSize: "1rem", lineHeight: "1rem", textAlign: "center" }}>{i}</Typography>
                </CardContent>
            </Card>
        </Grid>);
    }

    const created3dData=[];
    let perRow3d = [];
    
    for(let i=0; i<total3dNumbers; i++) {
        if (i!==0 && i%perRow3dNumbers===0) {
            created3dData.push(<Grid key={i/perRow3dNumbers} container sx={{ mt: "1rem" }} spacing={2}>
                {perRow3d}
            </Grid>);
            perRow3d =[]
        }
        let number = selectedPrefix+(((i<10)?"0":"")+i);
        let betNumber = betNumbers3d.find(bet => bet.number === number);
        perRow3d.push(<Grid key={i} item sm={2.4}>
            <Card sx={{backgroundColor: "transparent", border: "none"}}>
                <CardContent sx={{backgroundColor: "rgba(241, 241, 241, 1)"}}>
                    <Typography sx={{ fontSize: "1.5rem", textAlign: "center"}} color="secondary">{number}</Typography>
                    <Typography sx={{ mt: "0.5rem", textAlign: "center" }}>{betNumber? betNumber.amount: 0}</Typography>
                </CardContent>
            </Card>
        </Grid>);
        if (i===total3dNumbers-1) {
            created3dData.push(<Grid key={i/perRow3dNumbers} container sx={{ mt: "1rem" }} spacing={2}>
                {perRow3d}
            </Grid>);
            perRow3d =[]
        }
    }

    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    }

    const handleOpenModal = () => {
        setModalOpen(true);
    }

    const [breakNumber, setBreakNumber] = useState("");
    const [breakAmount, setBreakAmount] = useState(0);
    const [checkAll, setCheckAll] = useState(false);

    const handleChangeBreakNumber = (e) => {
        setBreakNumber(e.target.value);
    }

    const handleChangeBreakAmount = (e) => {
        setBreakAmount(e.target.value);
    }

    const handleChangeCheckAll = () => {
        setBreakNumber(checkAll?"":"All Numbers");
        setCheckAll(!checkAll);
    }

    const handleSetBreakNumber = () => {
        if (!checkAll && breakNumber.trim()==="") {
            return;
        }
        if (breakAmount > 0) {
            if (breakTabValue === "2d") {
                dispatch(numberActions.create2DBreakNumber(checkAll?"": breakNumber, breakAmount));
            }
            if (breakTabValue === "3d") {
                dispatch(numberActions.create3DBreakNumber(checkAll?"": breakNumber, breakAmount));
            }
            setBreakNumber("");
            setCheckAll(false);
            handleCloseModal();
        }
    }

    const agent = useSelector((state) => state.auth.agent);
    const { agenttype } = agent;

    return (
        <Layout>
            <Box>
                    <Typography variant="h5" sx={{ fontWeight: "500" }}>Dashboard</Typography>
                    <Grid container spacing={3}>
                        <StyledCard key="masteragent" sx={{ mt: '1.5rem' }} type="2D" number={winNum2d?.winningnumber}/>
                        <StyledCard key="agent" sx={{ mt: '1.5rem' }} type="3D" number={winNum3d?.winningnumber}/>
                        {/* {userTypes.map((type) => (
                            <StyledCard key={type} sx={{ mt: '1.5rem' }} type={type}/>
                        ))}  */}
                    </Grid>
                </Box>
            <Grid
            container
            direction="row"
            spacing={4}
            sx={{
                // height: '100%'
            }}
            >
                <Grid
                sm={7.5}
                item
                >
                    <Typography variant="h6" sx={{ mt: "1rem", opacity: "65%" }}>Total Unit for each Number</Typography>
                </Grid>
                <Grid item container sm={4.5}>
                    <Grid item sx={{flexGrow: 1}}>
                        <Typography variant="h5">Break Number</Typography>
                    </Grid>
                    {agenttype == "Admin" && (<Grid item>
                        <Button onClick={handleOpenModal} variant="contained" color="primary">New Break Number</Button>
                    </Grid>)}
                </Grid>
            </Grid>
            <Grid
            container
            direction="row"
            spacing={4}
            sx={{
                height: '100%'
            }}
            >
                <Grid
                sm={7.5}
                item
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    pr: '2rem',
                    borderRight: "1px solid rgba(0,0,0,0.12)",
                }}
                >
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="2D Total Number" value="2d" id="2d" sx={{ mr: "2rem" }} />
                        <Tab label="3D Total Number" value="3d" id="3d" />
                    </Tabs>
                    <TabPanel value={tabValue} index="2d">
                        {created2dData}
                    </TabPanel>
                    <TabPanel value={tabValue} index="3d">
                        <Grid container spacing={2} sx={{ mt: "1rem" }}>
                            {prefix3dData}
                        </Grid>
                        {(selectedPrefix!==null) && created3dData}
                    </TabPanel>
                </Grid>
                <Grid item sm={4.5}>
                    <BreakNumberPanel tabValue={breakTabValue} handleTabChange={handleBreakTabChange} />
                </Grid>
            </Grid>

            {/* modal */}
            <Dialog
            open={modalOpen}
            fullWidth
            maxWidth="xs"
            onClose={handleCloseModal}
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    padding: "16px 90px",
                    fontSize: "1.6rem",
                }}>
                    Break Number
                    <IconButton onClick={handleCloseModal} size="small" sx={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        border: "1px solid rgba(0,0,0,0.4)"
                    }}>
                        <CloseRounded fontSize="small"/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{
                    padding: "16px 90px"
                }}>
                    <Divider/>
                    <FormInputField value={breakNumber} disabled={checkAll} onChange={handleChangeBreakNumber} label="Number" id="number"/>
                    <FormInputField onChange={handleChangeBreakAmount} label="Break Amount" id="breakamount"/>
                    <FormControlLabel sx={{mt: "1rem"}} control={<Checkbox checked={checkAll} onChange={handleChangeCheckAll} />} label="All Numbers" />
                </DialogContent>
                <DialogActions
                    disableSpacing
                    sx={{
                        padding: "16px 90px",
                        flexDirection: "column",
                    }}
                >
                    <Button size='large' fullWidth variant="contained"
                    onClick={handleSetBreakNumber}
                    sx={{
                        fontWeight: "600"
                    }}>
                        Set
                    </Button>
                    <Button onClick={handleCloseModal} size='large' fullWidth variant="contained"
                    sx={{
                        mt: "1rem",
                        backgroundColor: "#F5F5F5",
                        color: "rgba(0,0,0,0.4)",
                        fontWeight: "600",
                        "&:hover": {
                            boxShadow: "none",
                            backgroundColor: "#F5F5F5"
                        }
                    }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
}

const BreakNumberPanel = ({tabValue, handleTabChange}) => {

    const agent = useSelector((state) => state.auth.agent);
    const { agenttype } = agent;

    const [selectedPrefix, setSelectedPrefix] = useState(null);

    const handleChangePrefix = (prefix) => {
        setSelectedPrefix(prefix)
    }

    const dispatch = useDispatch();

    const {breakNumbers2d, breakNumbers3d} = useSelector(state => state.numberManagement);

    useEffect(() => {
        dispatch(numberActions.get2dBreakNumbers({}));
        dispatch(numberActions.get3dBreakNumbers({}));
    }, []);

    const prefix3dData = [];
    for (let i=0; i<prefix3d; i++) {
        prefix3dData.push(<Grid item key={i}>
            <Card onClick={() => handleChangePrefix(i)} sx={{backgroundColor: "transparent", border: "none", cursor: "pointer"}}>
                <CardContent sx={{backgroundColor: (selectedPrefix===i)?"#7A0025":"rgba(241, 241, 241, 1)", padding: "10px !important"}}>
                    <Typography sx={{ color: (selectedPrefix===i)? "#fff": "#000", fontSize: "1rem", lineHeight: "1rem", textAlign: "center" }}>{i}</Typography>
                </CardContent>
            </Card>
        </Grid>);
    }

    return (
        <Box>
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="2D Break Number" value="2d" id="2d" sx={{ mr: "2rem" }} />
                <Tab label="3D Break Number" value="3d" id="3d" />
            </Tabs>
            <TabPanel value={tabValue} index="2d">
                <StyledTable 
                headers={headers}
                data={breakNumbers2d}
                isTransparentHeader={false}
                tableHeadsx={{
                    border: 'none',
                    padding: "16px 16px 0 16px",
                }}
                hasBreakNumberAction={agenttype == "Admin"? true: false}
                />
            </TabPanel>
            <TabPanel value={tabValue} index="3d">
                <Grid container spacing={2} sx={{ mt: "1rem" }}>
                    {prefix3dData}
                </Grid>
                {
                (selectedPrefix!==null) && (<StyledTable 
                headers={headers}
                data={breakNumbers3d.filter(breakNum => breakNum["breaknumber"].startsWith(selectedPrefix))}
                isTransparentHeader={false}
                tableHeadsx={{
                    border: 'none',
                    padding: "16px 16px 0 16px",
                }}
                hasBreakNumberAction={agenttype == "Admin"? true: false}
                />)
                }
            </TabPanel>
        </Box>
    );
}

const FormInputField = ({label, id, InputProps, type='text', onChange, disabled=false, value }) => {
    return (
        <Box sx={{ mt: '1rem' }}>
            <InputLabel htmlFor={id} sx={{ color: "#222", mb: '1rem' }}>{label}</InputLabel>
            <TextField
            disabled={disabled}
            onChange={onChange}
            value={value}
            id={id}
            type={type}
            fullWidth
            size="small"
            placeholder={label}
            InputProps={InputProps}
            sx={{
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&:hover fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&.Mui-focused fieldset": {
                        borderWidth: "1px",
                        borderColor: "rgba(0,0,0,0.4)"
                    }
                }
            }}
            />
        </Box>
    )
}

const StyledCard = ({sx, type, number}) => {
    return (
        <Grid item>
            <Card variant="outlined" sx={{...sx, width: "280px", border: "0.9px solid rgba(0,0,0,0.1)" }}>
                <CardContent>
                    <Typography sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{type}</Typography>
                    <Typography sx={{ fontSize: "0.7rem" }}>ပေါက်ဂဏန်း</Typography>
                    <Typography variant="h4" sx={{ mt: "1.5rem", fontWeight: "bold" }}>{number}</Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}