import Sidebar from '../components/Sidebar';
import { Box, Toolbar } from '@mui/material';
import Navbar from '../components/Navbar';
import { useEffect, useRef, useState } from 'react';

export default function Layout({children}) {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [toolbarHeight, setToolbarHeight] = useState(0);

    const toolbarRef = useRef(null);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    useEffect(() => {
        setToolbarHeight(toolbarRef.current.offsetHeight);
    }, [])

    const drawerWidth = 280;
    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', maxHeight: '100vh' }}>
            <Navbar drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle}/>
            <Sidebar drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}/>
            <Box component="main" sx={{
                width: {
                    sm: `calc(100% - ${drawerWidth}px)`
                },
                ml: {
                    sm:  `${drawerWidth}px`
                },
                backgroundColor: "#FBFBFB",
                display: {
                    sm: 'flex'
                },
                flexDirection: {
                    sm: 'column'
                }
            }}>
                <Toolbar ref={toolbarRef}/>
                <Box sx={{
                    flexGrow: 1,
                    margin: "1rem 2rem",
                    maxHeight: {
                        sm: `calc(100% - ${toolbarHeight}px)`
                    },
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}