import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, Grid, IconButton, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Logo from '../assets/images/Logo.png';
import { userActions } from "../store/actions";

const StyledCard = ({children}) => {
    return (
        <Grid item>
            <Card variant="outlined"
            sx={{
                margin: "auto",
                mt: "18vh",
                width: "32%",
                pb: "2rem",
                border: "1.2px solid rgba(11, 7, 0, 0.15)",
                borderRadius: "10px"
            }}
            >
                <CardContent sx={{ width: "80%", margin: "auto" }}>
                    {children}
                </CardContent>
            </Card>
        </Grid>
    );
}

const Login = () => {
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const usernameHandler = (event) => {
        setUsername(event.target.value);
        if (password.trim() !== "" && event.target.value.trim() !== "") {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }

    const passwordHandler = (event) => {
        setPassword(event.target.value);
        if (event.target.value.trim() !== "" && username.trim() !== "") {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }

    const loginHandler = () => {
        dispatch(userActions.login({ username, password }));
    }

    return (
        <StyledCard>
            <Avatar src={Logo} sx={{ borderRadius: '0px', width: '115px', height: '85px', margin: '15px auto' }} />
            <Typography variant="body1" textAlign="center" sx={{ color: "rgba(0,0,0,0.45)" }}>Please login to your account</Typography>
            <Box sx={{mt: "1.5rem"}}>
                <InputLabel htmlFor="username" sx={{ color: "#222", mb: '0.5rem', fontWeight: "w500" }}>Username</InputLabel>
                <TextField
                id="username"
                onChange={usernameHandler}
                fullWidth
                placeholder="Enter username"
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "rgba(0,0,0,0.4)"
                        },
                        "&:hover fieldset": {
                            borderColor: "rgba(0,0,0,0.4)"
                        },
                        "&.Mui-focused fieldset": {
                            borderWidth: "1px",
                            borderColor: "rgba(0,0,0,0.4)"
                        }
                    }
                }}
                />
            </Box>
            <Box sx={{mt: "1.5rem"}}>
                <InputLabel htmlFor="password" sx={{ color: "#222", mb: '0.5rem', fontWeight: "w500" }}>Password</InputLabel>
                <TextField
                id="password"
                onChange={passwordHandler}
                type={showPassword? 'text': 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            onClick={handleShowPassword}>
                                {showPassword? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                fullWidth
                placeholder="Enter password"
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "rgba(0,0,0,0.4)"
                        },
                        "&:hover fieldset": {
                            borderColor: "rgba(0,0,0,0.4)"
                        },
                        "&.Mui-focused fieldset": {
                            borderWidth: "1px",
                            borderColor: "rgba(0,0,0,0.4)"
                        }
                    }
                }}
                />
            </Box>
            <Button disabled={isFormValid? false: true} onClick={loginHandler} size="large" variant="contained" fullWidth sx={{ mt: '1.5rem', fontWeight: "500", fontSize: "1rem" }}>Login</Button>
        </StyledCard>
    );
}

export default Login;