import { apiService } from "../../services/api.services";
import { numberConst } from "../constants"
import { statusActions } from "./status.action";

const set2dWinningNumbers = (winningNumbers, count) => ({
    type: numberConst.SET_2D_WINNING_NUMBERS,
    winningNumbers,
    count,
});

const set3dWinningNumbers = (winningNumbers, count) => ({
    type: numberConst.SET_3D_WINNING_NUMBERS,
    winningNumbers,
    count
});

const set2dBetNumbers = (betNumbers, totalAmounts, count) => ({
    type: numberConst.SET_2D_BET_NUMBERS,
    betNumbers,
    totalAmounts,
    count,
});

const set3dBetNumbers = (betNumbers, totalAmounts, count) => ({
    type: numberConst.SET_3D_BET_NUMBERS,
    betNumbers,
    totalAmounts,
    count
});

const set2dBreakNumbers = (breakNumbers, count) => ({
    type: numberConst.SET_2D_BREAK_NUMBERS,
    breakNumbers,
    count,
});

const set3dBreakNumbers = (breakNumbers, count) => ({
    type: numberConst.SET_3D_BREAK_NUMBERS,
    breakNumbers,
    count
});

const set2dround = round => ({
    type: numberConst.SET_2D_ROUND,
    round
});

const set3dround = round => ({
    type: numberConst.SET_3D_ROUND,
    round
});

const create2DBreakNumber = (breaknumber, breakamount) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/set2dbreaknumber?breaknumber="+breaknumber+"&breakamount="+breakamount);
            const { breaknumbers, count } = response;
            dispatch(set2dBreakNumbers(breaknumbers, count));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const create3DBreakNumber = (breaknumber, breakamount) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/set3dbreaknumber?breaknumber="+breaknumber+"&breakamount="+breakamount);
            const { breaknumbers, count } = response;
            dispatch(set3dBreakNumbers(breaknumbers, count));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get2dBreakNumbers = ({page, startDate, endDate}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/2dbreaknumbers?page="+page+"&startDate="+startDate+"&endDate="+endDate);
            const {breaknumbers, count} = response;
            dispatch(set2dBreakNumbers(breaknumbers.map((number) => ({
                ...number,
                date: new Date(number.createdat).toLocaleDateString(),
            })), count));
        } catch(err) {
            dispatch(set2dBreakNumbers([]));
            
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get3dBreakNumbers = ({page, startDate, endDate}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/3dbreaknumbers?page="+page+"&startDate="+startDate+"&endDate="+endDate);
            const {breaknumbers, count} = response;
            dispatch(set3dBreakNumbers(breaknumbers.map((number) => ({
                ...number,
                date: new Date(number.createdat).toLocaleDateString(),
            })), count));
        } catch(err) {
            dispatch(set3dBreakNumbers([]));
            
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get2dBetNumbers = (startDate, endDate, round) => {
    console.log("api selected round: ", round);
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/2dbetnumbers?startDate="+startDate+"&endDate="+endDate+"&roundtype="+round);
            const {betnumbers, totalamounts, count} = response;
            dispatch(set2dBetNumbers(betnumbers, totalamounts, count));
        } catch(err) {
            dispatch(set2dBetNumbers([]));
            
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get3dBetNumbers = (startDate, endDate) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/3dbetnumbers?startDate="+startDate+"&endDate="+endDate);
            const {betnumbers, totalamounts, count} = response;
            dispatch(set3dBetNumbers(betnumbers, totalamounts, count));
        } catch(err) {
            dispatch(set3dBetNumbers([]));
            
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const create2DRound = (roundname, winningdate) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/open2dRound?roundname="+roundname+"&roundwinningdate="+winningdate);
            const { round } = response;
            let date = new Date(new Date(round.roundwinningdate).getTime() - ((-390) * 60 * 1000));
            let time = date.toISOString().split("T")[1].split(".")[0].split(":");
            time.pop();
            dispatch(set2dround({...round, date: date.toISOString().split("T")[0], time: time.join(":")}));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const create3DRound = (roundname, winningdate) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/open3dRound?roundname="+roundname+"&roundwinningdate="+winningdate);
            const { round } = response;
            let date = new Date(new Date(round.roundwinningdate).getTime() - ((-390) * 60 * 1000));
            let time = date.toISOString().split("T")[1].split(".")[0].split(":");
            time.pop();
            dispatch(set3dround({...round, date: date.toISOString().split("T")[0], time: time.join(":")}));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get2dRound = () => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/get2dround");
            const { round } = response;
            let date = new Date(new Date(round.roundwinningdate).getTime() - ((-390) * 60 * 1000));
            let time = date.toISOString().split("T")[1].split(".")[0].split(":");
            time.pop();
            dispatch(set2dround({...round, date: date.toISOString().split("T")[0], time: time.join(":")}));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get3dRound = () => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/get3dround");
            const { round } = response;
            let date = new Date(new Date(round.roundwinningdate).getTime() - ((-390) * 60 * 1000));
            let time = date.toISOString().split("T")[1].split(".")[0].split(":");
            time.pop();
            dispatch(set3dround({...round, date: date.toISOString().split("T")[0], time: time.join(":")}));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const set2dWinNumber = (winningnumber, showTime) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("post", "agent/set2dwinning", {
                winningnumber,
                showTime,
            });
            const { number } = response;
            dispatch(set2dround(null));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const set3dWinNumber = (winningnumber, showTime) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("post", "agent/set3dwinning", {
                winningnumber,
                showTime,
            });
            const { number } = response;
            dispatch(set3dround(null));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const set2dWinning = (num) => ({
    type: numberConst.SET_WIN_NUM_2D,
    num
});

const set3dWinning = (num) => ({
    type: numberConst.SET_WIN_NUM_3D,
    num
});

const get2dWinNumbers = (page=1, startDate, endDate) => {
    return async(dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/2dwinningnumbers?page="+page+"&startDate="+startDate+"&endDate="+endDate+"&limit=10");
            const {winningnumbers, count} = response;
            dispatch(set2dWinning(winningnumbers[0]));
            dispatch(set2dWinningNumbers(winningnumbers.map((num) => ({
                ...num,
                date: new Date(num.createdat).toLocaleString('en-US', {timeZone: "Asia/Yangon"}),
            })), count));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get3dWinNumbers = (page=1, startDate, endDate) => {
    return async(dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/3dwinningnumbers?page="+page+"&startDate="+startDate+"&endDate="+endDate+"&limit=10");
            const {winningnumbers, count} = response;
            dispatch(set3dWinning(winningnumbers[0]));
            dispatch(set3dWinningNumbers(winningnumbers.map((num) => ({
                ...num,
                date: new Date(num.createdat).toLocaleString('en-US', {timeZone: "Asia/Yangon"}),
            })), count));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

export const numberActions = {
    create2DBreakNumber,
    create3DBreakNumber,
    get2dBreakNumbers,
    get3dBreakNumbers,
    get2dBetNumbers,
    get3dBetNumbers,
    create2DRound,
    create3DRound,
    get2dRound,
    get3dRound,
    set2dWinNumber,
    set3dWinNumber,
    get2dWinNumbers: get2dWinNumbers,
    get3dWinNumbers: get3dWinNumbers
}