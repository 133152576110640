import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';

export default function SidebarMenuItem({onClick, title, PageIcon, active, isCollapse, open, child}) {

    return (
        <ListItem sx={{ padding: '0' }}>
            <ListItemButton 
            onClick={onClick}
            sx={{ 
            padding: '0.2rem 1.6rem',
            pl: child?"4rem": "1.6rem",
            opacity: (active && !open)?'1': '0.5',
            borderRight: (active && !open)?"0.2rem solid #A70033":'none',
            backgroundColor: (active && !open)?"rgba(167,0,51,0.15)":"#fff",
            '&:hover': {
                opacity: (active && !open)?'1': '0.5',
                borderRight: (active && !open)?"0.2rem solid #A70033":'none',
                backgroundColor: (active && !open)?"rgba(167,0,51,0.15)":"#fff",
                // "& .pageIcon": {
                //     fill: "#A70033 !important"
                // }
            }
            }}>
                <PageIcon className="pageIcon" style={{ 
                    width: '1.3rem',
                    height: '2.5rem',
                    justifyContent: 'center',
                    marginRight: '1rem',
                    fill: (active && !open)?'#A70033':"#000",
                    }}
                    />
                <ListItemText primary={title} sx={{ fontSize: '0.3rem' }}/>
                {isCollapse ? open ? <ExpandLess /> : <ExpandMore /> : ""}
            </ListItemButton>
        </ListItem>
    )
}