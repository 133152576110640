import { Button, Grid, Typography } from "@mui/material";
import StyledSearchBox from "../components/StyledSearchBox";
import StyledTable from "../components/StyledTable";
import Layout from "./Layout";
import mockData from '../mocks/index.json';
import CustomPagination from "../components/CustomPagination";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agentActions } from "../store/actions";

export default function PlayerManagement() {
    const { mockMasterAgents } = mockData;
    const tableCellsx = {
            "playerid": {},
            "name": {},
            "phone": {},
            "commission": {},
            "status": {
                
            }
        };
    const headers = [
        {
            "key": "playerid",
            "title": "hidden"
        },
        {
            "key": "name",
            "title": "Name"
        },
        {
            "key": "phoneno",
            "title": "Phone Number"
        },
        {
            "key": "unitbalance",
            "title": "Units"
        },
        {
            "key": "status",
            "title": "Status"
        },
        {
            "key": "createdat",
            "title": "Created On"
        },
    ];

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('createdat');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy===property && order==='asc';
        setOrder(isAsc? 'desc': 'asc');
        setOrderBy(property);
    }

    const fetchData = () => {
        dispatch(agentActions.getAllPlayers({page, keyword: searchValue, order, orderBy}));
    }

    const handleSearch = (e) => {
        if (e.keyCode === 13) {
            if (page === 1) {
                fetchData();
            } else {
                handlePageChange(null, 1);
            }
        }
    }

    const handleClearValue = () => {
        setSearchValue("");
        if (page === 1) {
            fetchData();
        } else {
            handlePageChange(null, 1);
        }
    }

    const handlePageChange = (e, value) => {
        setPage(value);
    }

    useEffect(() => {
        fetchData();
    }, [page, order, orderBy])

    const {players, currentPlayerCount} = useSelector((state) => state.agentManagement);

    return (
        <Layout>
            <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography variant="h5" sx={{ fontWeight: "500" }}>Player Management</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} sx={{ alignItems: "center" }}>
                        <Grid item>
                            <StyledSearchBox
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={handleSearch}
                            handleClearValue={handleClearValue}
                            placeholder="Search Player by Name"
                            sx={{
                                width: "300px"
                            }}/>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => navigate("/players/new")} variant="contained" color="primary">+ New Player</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <StyledTable
            headers={headers}
            data={players}
            tableCellsx = {tableCellsx}
            sx={{ backgroundColor: "transparent" }}
            hasAction={true}
            labelSort={true}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            />
            <CustomPagination
            handlePageChange={handlePageChange}
            page={1}
            perPage={10}
            dataCount={currentPlayerCount}
            />
        </Layout>
    );
}