import { getAgent } from "../../utils/agent";
import { authConst } from "../constants/auth.const";

const agent = JSON.parse(getAgent());
const initialState = {
    isAuth: agent? true :false,
    agent: agent? agent: {}
};

export function authenticate(state=initialState, action) {
    switch(action.type) {
        case authConst.SET_AUTH_USER:
            return {
                ...state,
                isAuth: action.isAuth,
                agent: action.agent
            };
        default:
            return state;
    }
}