const AGENT = "agent";

export const getAgent = () => (
    localStorage.getItem(AGENT)
);

export const setAgent = (value) => (
    localStorage.setItem(AGENT, value)
)

export const deleteAgent = () => {
    localStorage.removeItem(AGENT);
}