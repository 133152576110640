import { Button, Grid, Menu, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import CustomPagination from "../../components/CustomPagination";
import MyDateRangePicker from "../../components/MyDateRangePicker";
import StyledTable from "../../components/StyledTable";
import { agentActions } from "../../store/actions";
import { formatEndDateForApi, formatStartDateForApi } from "../../utils/misc";
import Layout from "../Layout";

export default function BetReportDetail() {
    const headers = [
        {
            key: "date",
            title: "Date",
        },
        {
            key: "betnumber",
            title: "Bet Number"
        },
        {
            key: "betamount",
            title: "Bet Amount"
        },
        {
            key: "slipStatus",
            title: "Status"
        },
        {
            key: "potentialwinningamount",
            title: "Potential Winning Amount"
        }
    ];

    const { betReport2dDetail ,betReport2dDetailCount, betReport3dDetail ,betReport3dDetailCount } = useSelector(state => state.agentManagement);

    const dispatch = useDispatch();
    const { type, roundid, playerid } = useParams();
    const { state } = useLocation();

    const fetchData = () => {
        if (type === "2D") {
            dispatch(agentActions.get2dBetReportDetail({page, roundid, playerid}));
        } else {
            dispatch(agentActions.get3dBetReportDetail({page, roundid, playerid}));
        }
    }

    const [page, setPage] = useState(1);

    const handlePageChange = (e, value) => {
        setPage(value);
    }

    useEffect(() => {
        fetchData();
    }, [page])

    return (
        <Layout>
            <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography variant="h5" sx={{ fontWeight: "500" }}>Bet Report Detail</Typography>
                    <Typography sx={{ fontWeight: "500", mt: 3 }}>Player - {state.playername}</Typography>
                    <Typography sx={{ fontWeight: "500" }}>Round - {state.roundname}</Typography>
                </Grid>
            </Grid>
            
            <StyledTable
            sx={{mt: '2rem', backgroundColor: "transparent"}}
            headers={headers}
            data={(type === "2D")? betReport2dDetail: betReport3dDetail}
            />
            <CustomPagination
            handlePageChange={handlePageChange}
            page={page}
            perPage={10}
            dataCount={(type === "2D")? betReport2dDetailCount: betReport3dDetailCount}
            />
        </Layout>
    );
}