export const numberConst = {
    SET_2D_BET_NUMBERS: "SET_2D_BET_NUMBERS",
    SET_3D_BET_NUMBERS: "SET_3D_BET_NUMBERS",
    SET_2D_BREAK_NUMBERS: "SET_2D_BREAK_NUMBERS",
    SET_3D_BREAK_NUMBERS: "SET_3D_BREAK_NUMBERS",
    SET_2D_WINNING_NUMBERS: "SET_2D_WINNING_NUMBERS",
    SET_3D_WINNING_NUMBERS: "SET_3D_WINNING_NUMBERS",
    SET_2D_ROUND: "SET_2D_ROUND",
    SET_3D_ROUND: "SET_3D_ROUND",
    SET_WIN_NUM_2D: "SET_WIN_NUM_2D",
    SET_WIN_NUM_3D: "SET_WIN_NUM_3D",
}