import { Box, Button, Card, CardContent, Grid, IconButton, InputAdornment, InputLabel, Table, TableBody, TableCell, tableCellClasses, TableRow, TextField, Typography } from "@mui/material";
import Layout from "./Layout";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agentActions } from "../store/actions";
import { useLocation, useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)((sx) => ({
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Inter, sans-serif',
        fontSize: "0.9rem",
        border: "none",
        fontWeight: "600",
        padding: "12px"
    },
    "&:nth-of-type(odd)": {
        fontWeight: "300"
    }
}));

const FormInputField = ({label, id, InputProps, onChange, type='text'}) => (
    <Grid item sm={6}>
        <InputLabel htmlFor={id} sx={{ color: "#222", mb: '1rem' }}>{label}</InputLabel>
        <TextField
        onChange={onChange}
        id={id}
        type={type}
        fullWidth
        size="small"
        placeholder={label}
        InputProps={InputProps}
        sx={{
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: "#000"
                },
                "&:hover fieldset": {
                    borderColor: "#000"
                },
                "&.Mui-focused fieldset": {
                    borderWidth: "1px",
                    borderColor: "#000"
                }
            }
        }}
        />
    </Grid>
)

const MasterAgentForm = ({
    setFullname,
    setUsername,
    setPassword,
    setPhoneno,
    setCommission2d,
    setCommission3d,
    setMultiplier2d,
    setMultiplier3d,
    onChangeHandler
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <Grid container spacing={2} sx={{ mt: '1rem' }}>
            <FormInputField onChange={(e)=>onChangeHandler(setFullname, e)} label="Name" id="name"/>
            <FormInputField onChange={(e)=>onChangeHandler(setUsername, e)} label="Username" id="username"/>
            <FormInputField onChange={(e)=>onChangeHandler(setPassword, e)} label="Password" id="password"
            type={showPassword? 'text': 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        onClick={handleShowPassword}>
                            {showPassword? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}/>
            <FormInputField onChange={(e)=>onChangeHandler(setPhoneno, e)} label="Phone Number" id="phoneno"/>
            <FormInputField onChange={(e)=>onChangeHandler(setCommission2d, e)} label="2D Commission" id="2dcommission"/>
            <FormInputField onChange={(e)=>onChangeHandler(setCommission3d, e)} label="3D Commission" id="3dcommission"/>
            <FormInputField onChange={(e)=>onChangeHandler(setMultiplier2d, e)} label="2D Multiplier" id="2dwinningmultiplier"/>
            <FormInputField onChange={(e)=>onChangeHandler(setMultiplier3d, e)} label="3D Multiplier" id="3dwinningmultiplier"/>
        </Grid>
    )
}

const InformationCard = ({name}) => (
    <Card variant="outlined" sx={{
        border: 'none',
        backgroundColor: "#F3F3F3",
        borderRadius: '12px'
    }}>
        <CardContent>
            <Table>
                <TableBody>
                    <TableRow>
                        <StyledTableCell>Created by</StyledTableCell>
                        <StyledTableCell>{name?? "Loading"}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Created on</StyledTableCell>
                        <StyledTableCell>{(new Date().getMonth()+1)+"/"+new Date().getDate()+"/"+new Date().getFullYear()}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Updated by</StyledTableCell>
                        <StyledTableCell>-</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Updated on</StyledTableCell>
                        <StyledTableCell>-</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Deleted by</StyledTableCell>
                        <StyledTableCell>-</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Deleted on</StyledTableCell>
                        <StyledTableCell>-</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </CardContent>
    </Card>
)

export default function NewMasterAgentForm() {

    const { name } = useSelector((state) => state.auth.agent);
    const [fullname, setFullname] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [phoneno, setPhoneno] = useState("");
    const [commission2d, setCommission2d] = useState("");
    const [commission3d, setCommission3d] = useState("");
    const [multiplier2d, setMultiplier2d] = useState("");
    const [multiplier3d, setMultiplier3d] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const validateForm = () => {
        // console.log(fullname.trim()=="", username=="", password=="", phoneno=="", commission=="", multiplier2d=="", multiplier3d=="");
        if (fullname.trim() != "" && username.trim() != "" && password.trim() != "" && phoneno.trim() != "" && commission2d.trim() != "" && commission3d.trim() != "" && multiplier2d.trim() != "" && multiplier3d.trim() != "") {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }

    const onChangeHandler = (handler, e) => {
        if (e.target.value.trim == "") {
            setIsFormValid(false);
        }
        handler(e.target.value);
    }

    useEffect(() => {
        validateForm();
    }, [fullname, username, password, phoneno, commission2d, commission3d, multiplier2d, multiplier3d]);

    const createHandler = async () => {
        if (!isFormValid) return;
        let data = {
            name: fullname,
            username,
            password,
            phoneno,
            "2dcommission": commission2d,
            "3dcommission": commission3d,
            "2dwinningmultiplier": multiplier2d,
            "3dwinningmultiplier": multiplier3d,
            "agenttype": location.pathname.includes("masteragent")?"Master Agent": "Agent"
        }
        await dispatch(agentActions.createAgent(data));
        navigate(location.pathname.includes("masteragents")?"/masteragents": "/agents");
    }

    return (
        <Layout>
            <Typography variant="h5">Create New {location.pathname.includes("masteragents")? "Master ":""}Agent</Typography>
            <Grid
            container
            direction="row"
            spacing={4}
            sx={{
                height: '100%'
            }}
            >
                <Grid
                sm={8.5}
                item
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "space-between",
                    pr: '2rem',
                    borderRight: "1px solid rgba(0,0,0,0.12)",
                }}
                >
                    <Box>
                        <Typography variant="body1" sx={{ opacity: "45%", mt: "1.5rem", fontSize: "15px" }}>Agent Info</Typography>
                        <Grid container sx={{ mt: "0.1rem" }} spacing={2}>
                            <Grid item>
                                <Typography sx={{ opacity: "60%", fontSize: "15px" }}>Agent Type:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography sx={{ fontSize: "15px" }}>{location.pathname.includes("masteragent")?"Master Agent": "Agent"}</Typography>
                            </Grid>
                        </Grid>
                        <MasterAgentForm
                            setFullname={setFullname}
                            setUsername={setUsername}
                            setPassword={setPassword}
                            setPhoneno={setPhoneno}
                            setCommission2d={setCommission2d}
                            setCommission3d={setCommission3d}
                            setMultiplier2d={setMultiplier2d}
                            setMultiplier3d={setMultiplier3d}
                            onChangeHandler={onChangeHandler}
                        />
                    </Box>
                    <Grid container justifyContent="flex-end">
                        <Grid item sm={6} container spacing={2}>
                            <Grid item sm={6}>
                                <Button size='large' fullWidth variant="contained"
                                sx={{
                                    backgroundColor: "#F5F5F5",
                                    color: "rgba(0,0,0,0.4)",
                                    fontWeight: "600",
                                    "&:hover": {
                                        boxShadow: "none",
                                        backgroundColor: "#F5F5F5"
                                    }
                                }}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item sm={6}>
                                <Button size='large' fullWidth variant="contained"
                                onClick={createHandler}
                                sx={{
                                    fontWeight: "600"
                                }}>
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={3.5}>
                    <InformationCard name={name}/>
                </Grid>
            </Grid>
        </Layout>
    )
}