import {
    Box,
    Stack,
    Avatar,
    List,
    Drawer,
    Collapse,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    Divider,
    DialogActions,
    InputAdornment,
    InputLabel,
    Button,
    TextField,
} from '@mui/material';
import Logo from '../assets/images/Logo.png';
import {ReactComponent as LogoutIcon} from '../assets/icons/Logout.svg';
import SidebarMenuItem from './SidebarMenuItem';
import routes from '../routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { agentActions, userActions } from '../store/actions';
import { useState } from 'react';
import { CloseRounded, ContactPageOutlined, Key, Visibility, VisibilityOff } from '@mui/icons-material';


export default function Sidebar ({drawerWidth, mobileOpen, handleDrawerToggle}) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentPath = pathname.split('/').slice(1)[0];

    const agent = useSelector((state) => state.auth.agent);
    const { agenttype } = agent;

    const handleMenuClick = (path) => {
        navigate(path);
    }

    const logoutHandler = () => {
        dispatch(userActions.logout());
    }

    const [collapseKey, setCollapseKey] = useState(null);

    const handleCollapse = (key) => {
        setCollapseKey(key);
    }

    // reset password model
    const [resetPasswordModelOpen, setResetPasswordModelOpen] = useState(false);

    const handleCloseResetPasswordModel = () => {
        setResetPasswordModelOpen(false);
    }

    const handleOpenResetPasswordModel = () => {
        setResetPasswordModelOpen(true);
    }

    // show password
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const resetPasswordHandler = async () => {
        if (password.trim() !== "" && oldPassword.trim() !== "") {
                await dispatch(agentActions.changePassword({newpassword: password, oldpassword: oldPassword}));
            handleShowPassword();
            handleCloseResetPasswordModel();
        }
    }

    const drawer = (
        <div style={{ height: '100%' }}>
            <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            sx={{ height: '100%' }}
            >
                <Box sx={{ width: drawerWidth }}>
                    <Avatar src={Logo} sx={{ borderRadius: '0px', width: '114px', height: '85px', margin: '15px auto' }} />
                    <List>
                        {routes.map((page) => {
                            if (page.collapse) {
                                return (
                                    <Box key={page.key}>
                                        <SidebarMenuItem onClick={() => handleCollapse(collapseKey===null?page.key: null)} open={collapseKey!==null} key={page.key} title={page.title} PageIcon={page.icon} active={currentPath.includes(page.key)} isCollapse={page.collapse} />
                                        <Collapse in={collapseKey===page.key} unmountOnExit>
                                            <List component="div" disablePadding>
                                                {page.children.map((child) => {
                                                    if (child.children) {
                                                        child = child.children[0];
                                                    }
                                                    if (agenttype === "Admin") {
                                                        if (child.key === "player-detail-report" ||
                                                            child.key === "unit-balance-report") {
                                                            return null;
                                                        }
                                                    }
                                                    if (agenttype === "Master Agent") {
                                                        if (child.key === "master-agent-detail-report" ||
                                                            child.key === "gross-profit-report") {
                                                            return null;
                                                        }
                                                    }
                                                    if (agenttype === "Agent") {
                                                        if (child.key === "master-agent-detail-report" ||
                                                            child.key === "agent-detail-report" ||
                                                            child.key === "comission-report" ||
                                                            child.key === "gross-profit-report") {
                                                            return null;
                                                        }
                                                    }
                                                    return (
                                                        <SidebarMenuItem onClick={() => handleMenuClick(child.path)} child={true} key={child.key} title={child.title} PageIcon={child.icon} active={child.key===currentPath} />
                                                    )
                                                })}
                                            </List>
                                        </Collapse>
                                    </Box>
                                )
                            }
                            if (page.children) {
                                page = page.children[0];
                            }
                            if (agenttype !== "Admin") {
                                if (page.key.includes("masteragent") || 
                                    page.path.includes("winning") ||
                                    page.key === "announcement") {
                                    return null;
                                }
                            }
                            if (agenttype !== "Master Agent" && agenttype !== "Admin") {
                                if (page.key === "agents") {
                                    return null;
                                }
                            }
                            if (agenttype !== "Agent" && agenttype !== "Master Agent") {
                                if (page.path.includes("player")) {
                                    return null;
                                }
                            }
                            return (
                                <SidebarMenuItem onClick={() => handleMenuClick(page.path)} key={page.key} title={page.title} PageIcon={page.icon} active={page.key===currentPath} />
                            )
                        })}
                    </List>
                </Box>
                <Box sx={{ width: drawerWidth }}>
                <List>
                    {(agenttype === "Admin") &&
                        (<SidebarMenuItem onClick={() => handleMenuClick("/contact")} title="Contact" PageIcon={ContactPageOutlined} active={currentPath==="contact"} />)
                    }
                    <SidebarMenuItem onClick={handleOpenResetPasswordModel} title="ChangePassword" PageIcon={Key} active={false} />
                    <SidebarMenuItem onClick={logoutHandler} title="Logout" PageIcon={LogoutIcon} active={false} />
                </List>
                </Box>

            </Stack>
        </div>
    );

    return (
        <>
            <Drawer
            open={mobileOpen}
            onClose={handleDrawerToggle}
            variant="temporary"
            sx={{ display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth } }}
            >
                {drawer}
            </Drawer>
            <Drawer
            variant="permanent"
            sx={{ display: { xs: 'none', sm: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth } }}
            >
                {drawer}
            </Drawer>

            {/* Password Reset Modal */}
            <Dialog
                open={resetPasswordModelOpen}
                fullWidth
                maxWidth="xs"
                onClose={handleCloseResetPasswordModel}
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    padding: "16px 90px",
                    fontSize: "1.6rem",
                }}>
                    Reset Password
                    <IconButton onClick={handleCloseResetPasswordModel} size="small" sx={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        border: "1px solid rgba(0,0,0,0.4)"
                    }}>
                        <CloseRounded fontSize="small"/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{
                    padding: "16px 90px"
                }}>
                    <Divider/>
                    <FormInputField label="Old Password" id="password" 
                    onChange={(e) => {
                        setOldPassword(e.target.value);
                    }}
                    />
                    <FormInputField label="New Password" id="password" 
                    type={showPassword? 'text': 'password'}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                onClick={handleShowPassword}>
                                    {showPassword? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}/>
                </DialogContent>
                <DialogActions
                    disableSpacing
                    sx={{
                        padding: "16px 90px",
                        flexDirection: "column",
                    }}
                >
                    <Button size='large' fullWidth variant="contained"
                    onClick={resetPasswordHandler}
                    sx={{
                        fontWeight: "600"
                    }}>
                        Change Password
                    </Button>
                    <Button onClick={handleCloseResetPasswordModel} size='large' fullWidth variant="contained"
                    sx={{
                        mt: "1rem",
                        backgroundColor: "#F5F5F5",
                        color: "rgba(0,0,0,0.4)",
                        fontWeight: "600",
                        "&:hover": {
                            boxShadow: "none",
                            backgroundColor: "#F5F5F5"
                        }
                    }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const FormInputField = ({label, id, InputProps, type='text', onChange, inputRef, disabled=false, value}) => {
    return (
        <Box sx={{ mt: '1rem' }}>
            <InputLabel htmlFor={id} sx={{ color: "#222", mb: '1rem' }}>{label}</InputLabel>
            <TextField
            disabled={disabled}
            onChange={onChange}
            defaultValue={value}
            inputRef={inputRef}
            id={id}
            type={type}
            fullWidth
            size="small"
            placeholder={label}
            InputProps={InputProps}
            sx={{
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&:hover fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&.Mui-focused fieldset": {
                        borderWidth: "1px",
                        borderColor: "rgba(0,0,0,0.4)"
                    }
                }
            }}
            />
        </Box>
    )
}