import { Button, Grid, Menu, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../components/CustomPagination";
import MyDateRangePicker from "../../components/MyDateRangePicker";
import StyledTable from "../../components/StyledTable";
import { agentActions } from "../../store/actions";
import { formatEndDateForApi, formatStartDateForApi } from "../../utils/misc";
import Layout from "../Layout";

export default function CommissionReport() {
    const headers = [
        {
            key: "name",
            title: "Name",
        },
        {
            key: "phoneno",
            title: "Phone Number"
        },
        {
            key: "2dcommission",
            title: "2D Commission"
        },
        {
            key: "3dcommission",
            title: "3D Commission"
        },
        {
            key: "createdat",
            title: "Created Date"
        },
        {
            key: "updatedat",
            title: "Updated Date"
        }
    ];

    const { agenttype }  = useSelector((state) => state.auth.agent);
    const {agents, currentAgentCount, masterAgents, currentMasterAgentCount} = useSelector(state => state.agentManagement);

    const dispatch = useDispatch();

    const fetchData = () => {
        if (value.startDate && value.endDate) {
            if (agenttype === "Admin") {
                dispatch(agentActions.getAllMasterAgents({page, startDate: formatStartDateForApi(value?.startDate), endDate: formatEndDateForApi(value?.endDate)}));
            } 
            if (agenttype === "Master Agent") {
                dispatch(agentActions.getAllAgents({page, startDate: formatStartDateForApi(value?.startDate), endDate: formatEndDateForApi(value?.endDate)}));
            }
        } else {
            if (agenttype === "Admin") {
                dispatch(agentActions.getAllMasterAgents({page}));
            }
            if (agenttype === "Master Agent") {
                dispatch(agentActions.getAllAgents({page}));
            }
        }
    }

    const [page, setPage] = useState(1);

    const handlePageChange = (e, value) => {
        setPage(value);
    }

    useEffect(() => {
        fetchData();
    }, [page])

    const [datepickerAnchorEl, setDatePickerAnchorEl] = useState(null);
    const open = Boolean(datepickerAnchorEl);
    const [value, setValue] = useState({});

    const toggleDatePicker = (e) => {
        if (datepickerAnchorEl !== null) {
            setDatePickerAnchorEl(null);
        } else {
            setDatePickerAnchorEl(e.currentTarget)
        }
    }

    useEffect(() => {
        fetchData();
    }, [value])

    return (
        <Layout>
            <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography variant="h5" sx={{ fontWeight: "500" }}>Commission Report</Typography>
                </Grid>
                <Grid item>
                    <Button onClick={toggleDatePicker} variant="contained" color="primary">Filter by date</Button>
                </Grid>
            </Grid>
            <Menu
                anchorEl={datepickerAnchorEl}
                open={open}
                // onClose={toggleDatePicker}
                sx={{
                    mt: "10px",
                    "& .MuiPaper-root": {
                        minWidth: "0px",
                        minHeight: "0px"
                    },
                    "& ul": {
                        padding: 0,
                    }
                }}
            >
                <MyDateRangePicker open={open} toggle={toggleDatePicker} value={value} setValue={setValue} />
            </Menu>
            {(value.startDate && value.endDate) && (
                <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography>{"From "+new Date(value?.startDate).toLocaleDateString() +" To "+new Date(value?.endDate).toLocaleDateString()}</Typography>
                </Grid>
                <Grid item>
                    <Button onClick={() => setValue({})} variant="contained" color="primary">Clear</Button>
                </Grid>
            </Grid>
            )}
            <StyledTable
            sx={{mt: '2rem', backgroundColor: "transparent"}}
            headers={headers}
            data={(agenttype === "Admin")? masterAgents: agents}
            />
            <CustomPagination
            handlePageChange={handlePageChange}
            page={page}
            perPage={10}
            dataCount={(agenttype === "Admin")? currentMasterAgentCount: currentAgentCount}
            />
        </Layout>
    );
}