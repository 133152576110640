import { DateRangePicker } from "mui-daterange-picker";

export default function MyDateRangePicker({open, toggle, value, setValue}) {

    return (
        <DateRangePicker
            open={open}
            toggle={toggle}
            initialDateRange={value}
            onChange={(range) => {
                setValue(range);
                toggle();
            }}
        />
    );
}