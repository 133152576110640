import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agentActions } from "../store/actions";
import Layout from "./Layout";

export default function Contact() {

    const nameRef = useRef();

    const { announcement } = useSelector(state => state.agentManagement);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(agentActions.getAnnouncement());
    }, []);

    useEffect(() => {
        nameRef.current.value = announcement? JSON.parse(announcement?.contact) : "";
    }, [announcement]);

    const handleUpdate = () => {
        if (nameRef.current.value !== "") {
            dispatch(agentActions.updateContact({contact: JSON.stringify(nameRef.current.value.split(","))}));
        }
    }

    return (
        <Layout>
            <Typography variant="h5" sx={{ fontWeight: "500" }}>Contact Numbers</Typography>
            <Container sx={{ mt: 5 }}>
                <TextField
                id="phonenumbers"
                inputRef={nameRef}
                fullWidth
                size="small"
                placeholder="Enter phone numbers seperated by comma"
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "#000"
                        },
                        "&:hover fieldset": {
                            borderColor: "#000"
                        },
                        "&.Mui-focused fieldset": {
                            borderWidth: "1px",
                            borderColor: "#000"
                        }
                    }
                }}
                />
                <Grid container sx={{ mt: 3 }}>
                    <Grid item sx={{ flexGrow: 1 }} />
                    <Grid item>
                        <Button
                            onClick={handleUpdate}
                            size='large' 
                            variant="contained"
                            sx={{
                                fontWeight: "600"
                            }}
                        >
                                Save
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}