import React from 'react';
import Dashboard from './pages/Dashboard';
import {ReactComponent as DashboardIcon} from './assets/icons/Dashboard.svg';
import {ReactComponent as AgentIcon} from './assets/icons/Agent.svg';
import {ReactComponent as WinningIcon} from './assets/icons/Winning_number.svg';
import {ReactComponent as NumberIcon} from './assets/icons/Number.svg';
import {ReactComponent as ReportIcon} from './assets/icons/Report.svg';
import MasterAgentManagement from './pages/MasterAgentManagement';
import WinningNum from './pages/WinningNum';
import NumberManagement from './pages/NumberManagement';
import NewMasterAgentForm from './pages/NewMasterAgentForm';
import MasterAgentDetail from './pages/MasterAgentDetail';
import AgentManagement from './pages/AgentManagement';
import Dashboard2 from './pages/Dashboard2';
import NewPlayerForm from './pages/NewPlayerForm';
import PlayerManagement from './pages/PlayerManagement';
import PlayerDetail from './pages/PlayerDetail';
import AgentDetailReport from './pages/reports/AgentDetailReport';
import PlayerDetailReport from './pages/reports/PlayerDetailReport';
import CommissionReport from './pages/reports/CommissionReport';
import WinningNumberReport from './pages/reports/WinningNumberReport';
import UnitBalanceReport from './pages/reports/UnitBalanceReport';
import TransactionReport from './pages/reports/TransactionReport';
import BreakNumberReport from './pages/reports/BreakNumberReport';
import MasterAgentDetailReport from './pages/reports/MasterAgentDetailReport';
import Announcement from './pages/Announcement';
import { NotificationsOutlined } from '@mui/icons-material';
import GrossProfitReport from './pages/reports/GrossProfitReport';
import BetReport from './pages/reports/BetReport';
import BetReportDetail from './pages/reports/BetReportDetail';
import NumberReport from './pages/reports/NumberReport';

const routes = [
    {
        title: "Dashboard",
        key: "dashboard",
        icon: DashboardIcon,
        path: "/dashboard",
        component: <Dashboard/>,
        component2: <Dashboard2 />
    },
    {
        title: "Master Agents Management",
        key: "masteragents",
        icon: AgentIcon,
        children: [
            {
                title: "Master Agents Management",
                key: "masteragents",
                icon: AgentIcon,
                path: "/masteragents",
                component: <MasterAgentManagement/>,
            },
            {
                title: "Master Agents Management",
                key: "newMasteragents",
                icon: AgentIcon,
                path: "/masteragents/new",
                component: <NewMasterAgentForm/>
            },
            {
                title: "Master Agents Management",
                key: "newMasteragents",
                icon: AgentIcon,
                path: "/masteragents/:id",
                component: <MasterAgentDetail />
            },
        ]
    },
    {
        title: "Agents Management",
        key: "agents",
        icon: AgentIcon,
        children: [
            {
                title: "Agents Management",
                key: "agents",
                icon: AgentIcon,
                path: "/agents",
                component: <AgentManagement/>,
            },
            {
                title: "Agents Management",
                key: "newagents",
                icon: AgentIcon,
                path: "/agents/new",
                component: <NewMasterAgentForm/>
            },
            {
                title: "Agents Management",
                key: "newagents",
                icon: AgentIcon,
                path: "/agents/:id",
                component: <MasterAgentDetail />
            },
        ]
    },
    {
        title: "Player Management",
        key: "players",
        icon: AgentIcon,
        children: [
            {
                title: "Player Management",
                key: "players",
                icon: AgentIcon,
                path: "/players",
                component: <PlayerManagement/>,
            },
            {
                title: "Player Management",
                key: "newplayers",
                icon: AgentIcon,
                path: "/players/new",
                component: <NewPlayerForm/>
            },
            {
                title: "Player Management",
                key: "newplayers",
                icon: AgentIcon,
                path: "/players/:id",
                component: <PlayerDetail />
            },
        ]
    },
    // {
    //     title: 'Unit Management',
    //     key: "units",
    //     icon: UnitIcon,
    //     path: "/units",
    //     component: <UnitManagement/>
    // },
    {
        title: 'Winning Number',
        key: "winning",
        icon: WinningIcon,
        path: "/winning",
        component: <WinningNum/>
    },
    {
        title: 'Number Management',
        key: "numbers",
        icon: NumberIcon,
        path: "/numbers",
        component: <NumberManagement/>
    },
    {
        title: 'Announcement',
        key: "announcement",
        icon: NotificationsOutlined,
        path: "/announcement",
        component: <Announcement/>
    },
    {
        title: 'Reports',
        key: "report",
        icon: ReportIcon,
        collapse: true,
        children: [
            {
                title: 'Master Agent Detail Report',
                key: "master-agent-detail-report",
                icon: ReportIcon,
                path: "/master-agent-detail-report",
                component: <MasterAgentDetailReport/>
            },
            {
                title: 'Agent Detail Report',
                key: "agent-detail-report",
                icon: ReportIcon,
                path: "/agent-detail-report",
                component: <AgentDetailReport/>
            },
            {
                title: 'Player Detail Report',
                key: "player-detail-report",
                icon: ReportIcon,
                path: "/player-detail-report",
                component: <PlayerDetailReport/>
            },
            {
                title: 'Comission Report',
                key: "comission-report",
                icon: ReportIcon,
                path: "/comission-report",
                component: <CommissionReport/>
            },
            {
                title: 'Winning Number Report',
                key: "winning-number-report",
                icon: ReportIcon,
                path: "/winning-number-report",
                component: <WinningNumberReport/>
            },
            {
                title: 'Unit Balance Report',
                key: "unit-balance-report",
                icon: ReportIcon,
                path: "/unit-balance-report",
                component: <UnitBalanceReport/>
            },
            {
                title: 'Transaction Report',
                key: "transaction-report",
                icon: ReportIcon,
                path: "/transaction-report",
                component: <TransactionReport/>
            },
            {
                title: 'Break Number Report',
                key: "break-number-report",
                icon: ReportIcon,
                path: "/break-number-report",
                component: <BreakNumberReport/>
            },
            {
                title: 'Bet Report',
                key: "bet-report",
                icon: ReportIcon,
                children: [
                    {
                        title: 'Bet Report',
                        key: "bet-report",
                        icon: ReportIcon,
                        path: "/bet-report",
                        component: <BetReport/>
                    },
                    {
                        title: "Bet Report Detail",
                        key: "bet-report",
                        icon: ReportIcon,
                        path: "/bet-report/:type/:roundid/:playerid",
                        component: <BetReportDetail/>
                    },
                ]
            },
            {
                title: 'Number Report',
                key: "number-report",
                icon: ReportIcon,
                path: "/number-report",
                component: <NumberReport/>
            },
            {
                title: 'Gross Profit Report',
                key: "gross-profit-report",
                icon: ReportIcon,
                path: "/gross-profit-report",
                component: <GrossProfitReport/>
            },
        ]
    },
];

export default routes;