import { Button, Grid, Menu, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonSelectInput from "../../components/ButtonSelectInput";
import CustomPagination from "../../components/CustomPagination";
import MyDateRangePicker from "../../components/MyDateRangePicker";
import StyledTable from "../../components/StyledTable";
import { agentActions } from "../../store/actions";
import { formatEndDateForApi, formatStartDateForApi } from "../../utils/misc";
import Layout from "../Layout";

export default function BetReport() {
    const headers = [
        {
            key: "date",
            title: "Date",
        },
        // {
        //     key: "roundname",
        //     title: "Round"
        // },
        {
            key: "playername",
            title: "Player"
        },
        {
            key: "totalbetamount",
            title: "Total Bet Amount"
        },
        {
            key: "totalwonamount",
            title: "Potential Winning Amount"
        },
        {
            key: "roundstatus",
            title: "Round Status"
        }
    ];

    const { betReport2d ,betReport2dCount, betReport3d ,betReport3dCount } = useSelector((state) => state.agentManagement);

    const dispatch = useDispatch();

    const [page, setPage] = useState(1);

    const handlePageChange = (e, value) => {
        setPage(value);
    }

    const [datepickerAnchorEl, setDatePickerAnchorEl] = useState(null);
    const open = Boolean(datepickerAnchorEl);
    const [value, setValue] = useState({});

    const toggleDatePicker = (e) => {
        if (datepickerAnchorEl !== null) {
            setDatePickerAnchorEl(null);
        } else {
            setDatePickerAnchorEl(e.currentTarget)
        }
    }

    const items = ["2D", "3D"];
    const rounds = ["All", "AM", "PM"];

    const [selectedItem, setSelectedItem] = useState(items[0]);
    const [selectedRound, setSelectedRound] = useState(rounds[0]);

    const fetchData = useCallback(() => {
        if (value.startDate && value.endDate) {
            // if (selectedItem === "2D") {
                dispatch(agentActions.get2dBetReportData({page, startDate:formatStartDateForApi(value?.startDate), endDate:formatEndDateForApi(value?.endDate), round: selectedRound}));
            // } else {
                dispatch(agentActions.get3dBetReportData({page, startDate:formatStartDateForApi(value?.startDate), endDate:formatEndDateForApi(value?.endDate)}));
            // }
        } else {
            // if (selectedItem === "2D") {
                dispatch(agentActions.get2dBetReportData({page, round: selectedRound}));
            // } else {
                dispatch(agentActions.get3dBetReportData({page}));
            // }
        }
    }, [page, value, selectedItem, selectedRound, dispatch]);

    useEffect(() => {
        fetchData();
    }, [page, fetchData])

    useEffect(() => {
        setPage(1);
    }, [selectedItem, selectedRound, value])

    const handleSelectChange = (e, value) => {
        setSelectedItem(e.target.value);
    }

    const handleSelectedRoundChange = (e, value) => {
        setSelectedRound(e.target.value);
    }

    return (
        <Layout>
            <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography variant="h5" sx={{ fontWeight: "500" }}>Bet Report</Typography>
                </Grid>
                {
                    selectedItem==="2D" && (
                        <Grid item>
                            <ButtonSelectInput selected={selectedRound} items={rounds} onChange={handleSelectedRoundChange} />
                        </Grid>
                    )
                }
                <Grid item>
                    <ButtonSelectInput selected={selectedItem} items={items} onChange={handleSelectChange} />
                </Grid>
                <Grid item>
                    <Button onClick={toggleDatePicker} variant="contained" color="primary">Filter by date</Button>
                </Grid>
            </Grid>
            <Menu
                anchorEl={datepickerAnchorEl}
                open={open}
                // onClose={toggleDatePicker}
                sx={{
                    mt: "10px",
                    "& .MuiPaper-root": {
                        minWidth: "0px",
                        minHeight: "0px"
                    },
                    "& ul": {
                        padding: 0,
                    }
                }}
            >
                <MyDateRangePicker open={open} toggle={toggleDatePicker} value={value} setValue={setValue} />
            </Menu>
            {(value.startDate && value.endDate) && (
                <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography>{"From "+new Date(value?.startDate).toLocaleDateString() +" To "+new Date(value?.endDate).toLocaleDateString()}</Typography>
                </Grid>
                <Grid item>
                    <Button onClick={() => setValue({})} variant="contained" color="primary">Clear</Button>
                </Grid>
            </Grid>
            )}
            <StyledTable
            sx={{mt: '2rem', backgroundColor: "transparent"}}
            headers={headers}
            data={(selectedItem === "2D")? betReport2d: betReport3d}
            hasDetail
            detailType={selectedItem}
            />
            <CustomPagination
            handlePageChange={handlePageChange}
            page={page}
            perPage={10}
            dataCount={(selectedItem === "2D")? betReport2dCount: betReport3dCount}
            />
        </Layout>
    );
}