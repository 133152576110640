import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import Layout from "./Layout";
import mockData from '../mocks/index.json';
import StyledTable from "../components/StyledTable";
import { useEffect, useRef, useState } from "react";
import StyledSearchBox from "../components/StyledSearchBox";
import { useDispatch, useSelector } from "react-redux";
import { agentActions } from "../store/actions/agent.action";

const { mockUserTypes, mockUnitTransfer } = mockData

const Dashboard = () => {

    const headers = [
        {
            "key": "name",
            "title": "Name"
        },
        {
            "key": "topupamount",
            "title": "Unit"
        }
    ];

    const [userTypes, setUserTypes] = useState(mockUserTypes);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [cardHeight, setCardHeight] = useState(0);
    const headerRef = useRef(null);
    const cardWrapperRef = useRef(null);

    const dispatch = useDispatch();

    const { unitTopupHistoryCount ,unitTopupHistory } = useSelector((state) => state.agentManagement);
    const { unitWithdrawHistoryCount ,unitWithdrawHistory } = useSelector((state) => state.agentManagement);

    const fetchTopup = (keyword) => {
        dispatch(agentActions.getUnitHistory({channel:"agent", type: "topup", limit: 1000, keyword}));
    }

    const fetchWithdraw = (keyword) => {
        dispatch(agentActions.getUnitHistory({channel: "agent", type: "withdraw", limit: 1000, keyword}));
    }
    

    useEffect(() => {
        setHeaderHeight(headerRef.current.offsetHeight);
        setCardHeight(cardWrapperRef.current.offsetHeight);
        dispatch(agentActions.getCounts());
        fetchTopup("");
        fetchWithdraw("");
    }, []);

    const { agentCount, masterAgentCount } = useSelector((state) => state.agentManagement);

    return (
        <Layout>
            {/* <Box sx={{ maxHeight: '100%', backgroundColor: 'red', display: 'flex', flexDirection: 'column' }}> */}
                <Box ref={headerRef}>
                    <Typography variant="h5" sx={{ fontWeight: "500" }}>Dashboard</Typography>
                    <Grid container spacing={3}>
                        <StyledCard key="masteragent" sx={{ mt: '1.5rem' }} type="Master Agent" count={masterAgentCount}/>
                        <StyledCard key="agent" sx={{ mt: '1.5rem' }} type="Agent" count={agentCount}/>
                        {/* {userTypes.map((type) => (
                            <StyledCard key={type} sx={{ mt: '1.5rem' }} type={type}/>
                        ))}  */}
                    </Grid>
                    <Typography variant="h6" sx={{ mt: "1rem", opacity: "65%" }}>Other Information</Typography>
                </Box>
                <Grid ref={cardWrapperRef} container spacing={3} sx={{ mt: "0.05rem", height: `calc(100% - ${headerHeight}px)` }}>
                    <StyledTableCard
                    title="Unit Transfer for Master Agents"
                    headers={headers}
                    data={unitTopupHistory}
                    cardContentsx={{height: cardHeight}}
                    fetchData={fetchTopup}
                    />
                    <StyledTableCard
                    title="Unit Reedem for Master Agents"
                    headers={headers}
                    data={unitWithdrawHistory}
                    cardContentsx={{height: cardHeight}}
                    fetchData={fetchWithdraw}
                    />
                </Grid>
            {/* </Box> */}
        </Layout>
    );

}

const StyledCard = ({sx, type, count}) => {
    return (
        <Grid item>
            <Card variant="outlined" sx={{...sx, width: "280px", border: "0.9px solid rgba(0,0,0,0.1)" }}>
                <CardContent>
                    <Typography sx={{ fontSize: "0.9rem" }}>Number of {type}s</Typography>
                    <Box sx={{ backgroundColor: "rgba(0,0,0,0.3)", width: "30px", height: "2px", borderRadius: "30px" }}/>
                    <Typography variant="h4" sx={{ mt: "1.5rem" }}>{count}</Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}

const StyledTableCard = ({title, headers, data, cardContentsx, fetchData}) => {
    const [cardHeaderHeight, setCardHeaderHeight] = useState(0);
    const cardHeaderRef = useRef(null);

    const [searchValue, setSearchValue] = useState("");

    const handleSearch = (e) => {
        if (e.keyCode === 13) {
            fetchData(searchValue);
        }
    }

    const handleClearValue = () => {
        setSearchValue("");
        fetchData("");
    }

    useEffect(() => {
        setCardHeaderHeight(cardHeaderRef.current.offsetHeight);
    }, []);

    return (
        <Grid item xs={12} sm={12} md={6} sx={{
            ...cardContentsx,
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Card variant="outlined" sx={{ ...cardContentsx, border: 'none' }}>
                <CardContent sx={cardContentsx}>
                    <Box ref={cardHeaderRef}>
                        <Typography sx={{ fontSize: '1.2rem', mb: '1rem' }}>{title}</Typography>
                        <StyledSearchBox 
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={handleSearch}
                            handleClearValue={handleClearValue}
                            placeholder="Search Master Agent by Name"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    "& fieldset": {
                                        borderColor: "rgba(71, 99, 228, 0.5)"
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "rgba(71, 99, 228, 0.5)"
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "rgba(71, 99, 228, 0.5)"
                                    }
                                },
                            }}
                        />
                    </Box>
                    
                    <StyledTable
                    headers={headers}
                    data={data}
                    sx={{ mt: '1rem', height: `calc(100% - ${cardHeaderHeight}px)`, overflow: 'scroll' }}
                    isTransparentHeader={false}
                    tableHeadsx={{
                        border: 'none',
                        padding: "16px 16px 0 16px",
                    }}
                    />
                </CardContent>
            </Card>
        </Grid>
    );
}

export default Dashboard;