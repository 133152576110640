import { numberConst } from "../constants";

const initialState = {
    winningNumbers2d: [],
    winningNumbers3d: [],
    breakNumbers2d: [],
    breakNumbers3d: [],
    betNumbers2d: [],
    betNumbers3d: [],
    winningNumbers2dCount: 0,
    winningNumbers3dCount: 0,
    breakNumbers2dCount: 0,
    breakNumbers3dCount: 0,
    betNumbers2dCount: 0,
    betNumbers3dCount: 0,
    winNum2d: "",
    winNum3d: "",
    round2d: null,
    round3d: null,
    total2DBetAmount: 0,
    totalWonAmount2d: 0,
    total3DBetAmount: 0,
    totalWonAmount3d: 0,
};

export function numberManagement(state=initialState, action) {
    switch(action.type) {
        case numberConst.SET_2D_BET_NUMBERS:
            return {
                ...state,
                betNumbers2d: action.betNumbers,
                betNumbers2dCount: action.count,
                total2DBetAmount: action.totalAmounts.totalbetamount? parseInt(action.totalAmounts.totalbetamount): 0,
                totalWonAmount2d: action.totalAmounts.totalwonamount? parseInt(action.totalAmounts.totalwonamount): 0,
            };
        case numberConst.SET_3D_BET_NUMBERS:
            return {
                ...state,
                betNumbers3d: action.betNumbers,
                betNumbers3dCount: action.count,
                total3DBetAmount: action.totalAmounts.totalbetamount? parseInt(action.totalAmounts.totalbetamount): 0,
                totalWonAmount3d: action.totalAmounts.totalwonamount? parseInt(action.totalAmounts.totalwonamount): 0,
            };
        case numberConst.SET_2D_BREAK_NUMBERS:
            return {
                ...state,
                breakNumbers2d: action.breakNumbers,
                breakNumbers2dCount: action.count,
            };
        case numberConst.SET_3D_BREAK_NUMBERS:
            return {
                ...state,
                breakNumbers3d: action.breakNumbers,
                breakNumbers3dCount: action.count,
            };
        case numberConst.SET_2D_WINNING_NUMBERS:
            return {
                ...state,
                winningNumbers2d: action.winningNumbers,
                winningNumbers2dCount: action.count,
            };
        case numberConst.SET_3D_WINNING_NUMBERS:
            return {
                ...state,
                winningNumbers3d: action.winningNumbers,
                winningNumbers3dCount: action.count,
            };
        case numberConst.SET_2D_ROUND:
            return {
                ...state,
                round2d: action.round
            };
        case numberConst.SET_3D_ROUND:
            return {
                ...state,
                round3d: action.round
            };
        case numberConst.SET_WIN_NUM_2D:
            return {
                ...state,
                winNum2d: action.num
            };
        case numberConst.SET_WIN_NUM_3D:
            return {
                ...state,
                winNum3d: action.num
            };
        default:
            return state;
    }
}