import { Clear, Search as SearchIcon } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material"
import { useTheme } from '@mui/material/styles';

const StyledSearchBox = ({sx, placeholder, value, onChange, onKeyDown, handleClearValue}) => {
    const theme = useTheme()
    return (
        <TextField
            color="primary"
            fullWidth
            size="small"
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={value}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon sx={{color:"#000"}}/>
                    </InputAdornment>
                ),
                endAdornment: (
                    <IconButton sx={{
                            ml: 2,
                            visibility: (value === "")? 'hidden': 'visible'
                        }}
                        onClick={handleClearValue}
                    >
                        <Clear />
                    </IconButton>
                )
            }}
            sx={{
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: theme.palette.primary.main
                    },
                    "&:hover fieldset": {
                        borderColor: theme.palette.primary.main
                    },
                    "&.Mui-focused fieldset": {
                        borderWidth: "1px"
                    }
                }, ...sx
            }}
        />
    )
}

export default StyledSearchBox;