import { Box, Grid, Pagination, PaginationItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function CustomPagination({ page=1, perPage=10, dataCount=0, handlePageChange }) {
    const theme = useTheme();
    return (
        <Box sx={{ mt: "1rem" }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Typography variant="body2">
                        Showing {(page*perPage) - perPage +1} to {dataCount < (page*perPage)? dataCount: page*perPage} of {dataCount} entries
                    </Typography>
                </Grid>
                <Grid item>
                    <Pagination
                    sx={{
                        border: `1px solid ${theme.palette.secondary.light}`,
                        borderRadius: "5px",
                        "&  ul > li:last-child": {
                            "& button": {
                                border: "none",
                            }
                        },
                        "& .MuiPaginationItem-root": {
                            height: "32px",
                            lineHeight: "32px",
                            border: "none",
                            margin: 0,
                            borderRadius: 0,
                            borderRight: `1px solid ${theme.palette.secondary.light}`,
                        },
                        "& .MuiPaginationItem-previousNext": {
                            "& span": {
                                padding: '0 10px',
                                fontSize: '0.8rem'
                            },
                            "& span:nth-of-type(0)": {
                                borderRight: `1px solid ${theme.palette.secondary.light}`,
                            }
                        },
                        "& .MuiPaginationItem-root.Mui-selected": {
                            color: "#fff",
                            backgroundColor: theme.palette.secondary.main,
                            "&:hover": {
                                backgroundColor: theme.palette.secondary.main,
                            }
                        },
                    }}
                    variant="outlined"
                    shape="rounded"
                    count={Math.ceil(dataCount/perPage)}
                    page={page}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                        <PaginationItem
                            components={{
                                next: (props) => <span {...props}>Next</span>,
                                previous: (props) => <span {...props}>Previous</span>
                            }}
                            {...item}
                        />
                    )}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}