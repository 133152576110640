import { combineReducers } from "redux";
import { agentManagement } from "./agent.reducer";
import { authenticate } from './auth.reducer';
import { status } from "./status.reducer";
import { numberManagement } from "./number.reducer";
import { unitManagement } from "./unit.reducer";

const rootReducers = combineReducers({
    auth: authenticate,
    agentManagement,
    status,
    numberManagement,
    unitManagement,
});

export default rootReducers;