import { Box, MenuItem, Select } from "@mui/material";

export default function ButtonSelectInput({selected, items, onChange}) {
    return (
        <Box>
            <Select
            fullWidth
            value={selected}
            size="small"
            sx={{
                "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&:hover fieldset": {
                        borderColor: "rgba(0,0,0,0.4)"
                    },
                    "&.Mui-focused fieldset": {
                        borderWidth: "1px",
                        borderColor: "rgba(0,0,0,0.4)"
                    }
                }
            }}
            onChange={onChange}
            >
                {items.map((item) => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
            </Select>
        </Box>
    )
}