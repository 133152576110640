export const agentConst = {
    SET_ALL_AGENTS: "SET_ALL_AGENTS",
    SET_AGENT: "SET_AGENT",
    
    SET_ALL_MASTER_AGENTS: "SET_ALL_MASTER_AGENTS",
    SET_MASTER_AGENT: "SET_MASTER_AGENT",

    SET_ALL_PLAYERS: "SET_ALL_PLAYERS",
    SET_PLAYER: "SET_PLAYER",

    CREATE_AGENT: "CREATE_AGENT",
    CREATE_MASTER_AGENT: "CREATE_MASTER_AGENT",
    CREATE_PLAYER: "CREATE_PLAYER",

    UPDATE_AGENT: "UPDATE_AGENT",
    UPDATE_MASTER_AGENT: "UPDATE_MASTER_AGENT",
    UPDATE_PLAYER: "UPDATE_PLAYER",

    SET_ALL_AGENT_COUNT: "SET_ALL_AGENT_COUNT",
    SET_ALL_MASTER_AGENT_COUNT: "SET_ALL_MASTER_AGENT_COUNT",
    SET_ALL_PLAYER_COUNT: "SET_ALL_PLAYER_COUNT",

    SET_CURRENT_AGENT_COUNT: "SET_CURRENT_AGENT_COUNT",
    SET_CURRENT_MASTER_AGENT_COUNT: "SET_CURRENT_MASTER_AGENT_COUNT",
    SET_CURRENT_PLAYER_COUNT: "SET_CURRENT_PLAYER_COUNT",

    SET_UNIT_TOPUP_HISTORY: "SET_TOPUP_UNIT_HISTORY",
    SET_UNIT_WITHDRAW_HISTORY: "SET_WITHDRAW_UNIT_HISTORY",

    SET_ANNOUNCEMENT: "SET_ANNOUNCEMENT",

    SET_2D_GROSS_PROFIT: "SET_2D_GROSS_PROFIT",
    SET_3D_GROSS_PROFIT: "SET_3D_GROSS_PROFIT",

    SET_2D_BET_REPORT: "SET_2D_BET_REPORT",
    SET_3D_BET_REPORT: "SET_3D_BET_REPORT",

    SET_2D_BET_REPORT_DETAIL: "SET_2D_BET_REPORT_DETAIL",
    SET_3D_BET_REPORT_DETAIL: "SET_3D_BET_REPORT_DETAIL",
}