import { Box, Button, Grid, Menu, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import ButtonSelectInput from "../../components/ButtonSelectInput";
import CustomPagination from "../../components/CustomPagination";
import MyDateRangePicker from "../../components/MyDateRangePicker";
import StyledTable from "../../components/StyledTable";
import { agentActions, numberActions } from "../../store/actions";
import { formatEndDateForApi, formatStartDateForApi } from "../../utils/misc";
import Layout from "../Layout";

const items = ["2D", "3D"];

export default function BreakNumberReport() {
    const headers = [
        {
            key: "date",
            title: "Date",
        },
        {
            key: "breaknumber",
            title: "Break Number"
        },
        {
            key: "breakamount",
            title: "Break Amount"
        }
    ];

    const {breakNumbers2d, breakNumbers3d, breakNumbers2dCount, breakNumbers3dCount} = useSelector(state => state.numberManagement);

    const dispatch = useDispatch();

    const fetchData = () => {
        if (value.startDate && value.endDate) {
            dispatch(numberActions.get2dBreakNumbers({page, startDate: formatStartDateForApi(value?.startDate), endDate: formatEndDateForApi(value?.endDate)}));
            dispatch(numberActions.get3dBreakNumbers({page, startDate: formatStartDateForApi(value?.startDate), endDate: formatEndDateForApi(value?.endDate)}));
        } else {
            dispatch(numberActions.get2dBreakNumbers({page}));
            dispatch(numberActions.get3dBreakNumbers({page}));
        }
    }

    const [page, setPage] = useState(1);

    const handlePageChange = (e, value) => {
        setPage(value);
    }

    useEffect(() => {
        fetchData();
    }, [page])

    const [datepickerAnchorEl, setDatePickerAnchorEl] = useState(null);
    const open = Boolean(datepickerAnchorEl);
    const [value, setValue] = useState({});

    const toggleDatePicker = (e) => {
        if (datepickerAnchorEl !== null) {
            setDatePickerAnchorEl(null);
        } else {
            setDatePickerAnchorEl(e.currentTarget)
        }
    }

    useEffect(() => {
        fetchData();
    }, [value])

    const [selectedItem, setSelectedItem] = useState(items[0]);

    const handleSelectChange = (e, value) => {
        setSelectedItem(e.target.value);
    }

    useEffect(() => {
        fetchData();
    }, [page, value, selectedItem])

    return (
        <Layout>
            <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography variant="h5" sx={{ fontWeight: "500" }}>Break Number Report</Typography>
                </Grid>
                <Grid item>
                    <ButtonSelectInput selected={selectedItem} items={items} onChange={handleSelectChange} />
                </Grid>
                <Grid item>
                    <Button onClick={toggleDatePicker} variant="contained" color="primary">Filter by date</Button>
                </Grid>
            </Grid>
            <Menu
                anchorEl={datepickerAnchorEl}
                open={open}
                // onClose={toggleDatePicker}
                sx={{
                    mt: "10px",
                    "& .MuiPaper-root": {
                        minWidth: "0px",
                        minHeight: "0px"
                    },
                    "& ul": {
                        padding: 0,
                    }
                }}
            >
                <MyDateRangePicker open={open} toggle={toggleDatePicker} value={value} setValue={setValue} />
            </Menu>
            {(value.startDate && value.endDate) && (
                <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography>{"From "+new Date(value?.startDate).toLocaleDateString() +" To "+new Date(value?.endDate).toLocaleDateString()}</Typography>
                </Grid>
                <Grid item>
                    <Button onClick={() => setValue({})} variant="contained" color="primary">Clear</Button>
                </Grid>
            </Grid>
            )}
            <StyledTable
            sx={{mt: '2rem', backgroundColor: "transparent"}}
            headers={headers}
            data={(selectedItem == items[0])? breakNumbers2d: breakNumbers3d}
            />
            <CustomPagination
            handlePageChange={handlePageChange}
            page={page}
            perPage={10}
            dataCount={(selectedItem == items[0])? breakNumbers2dCount: breakNumbers3dCount}
            />
        </Layout>
    );
}