import { Alert, CssBaseline, Snackbar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import MyanmarSansPro from './assets/fonts/MyanmarSansPro.ttf';
import { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoadingDialog from './components/LoadingDialog';
import Contact from './pages/Contact';
import Login from './pages/Login';
import { PrivateRoutes } from './routers/PrivateRoutes';
import { PublicRoutes } from './routers/PublicRoutes';
import routes from './routes';
import { statusActions } from './store/actions';

export default function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#A70033"
      },
      secondary: {
        light: "rgba(122, 0, 37, 0.3)",
        main: "#7A0025"
      },
      white: {
        main: "#000"
      }
    },
    typography: {
      fontFamily: 'NotoSans, sans-serif',
      fontSize: 12,
      fontWeight: "400",
    },
    components: {
      // MuiCssBaseline: {
      //   styleOverrides: `
      //     @font-face {
      //       font-family: 'MyanmarSansPro';
      //       font-style: normal;
      //       font-weight: 400;
      //       src: url(${MyanmarSansPro}) format('ttf');
      //     }
      //   `,
      // },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            boxShadow: "none",
            backgroundColor: "#7A0025",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#7A0025"
            }
          },
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            padding: '0',
            fontSize: "1rem",
            color: "#000",
            "&.Mui-selected": {
              color: "#000"
            },
          }
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "& .MuiTabs-scroller": {
              "& .MuiTabs-indicator": {
                height: "3.5px",
                width: "40px !important"
              }
            }
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            border: "0.9px solid rgba(0,0,0,0.1)",
            boxShadow: "none"
          }
        }
      },
    }
  });

  const isAuth = useSelector((state) => state.auth.isAuth);
  const agent = useSelector((state) => state.auth.agent);
  const { agenttype } = agent;
  const { loading, success, message } = useSelector(state => state.status);

  console.log("Agent type: ", agenttype);

  const dispatch = useDispatch();

  const handleSnackBarClose = () => {
    dispatch(statusActions.setMessage(null));
    dispatch(statusActions.setSuccess(false));
  }

  return (
    <ThemeProvider theme={theme}>
      <Suspense>
        <CssBaseline/>
        <Routes>
          <Route element={PrivateRoutes(isAuth)} >
            {routes.map((route) => {
              let page = route;
              if (route.children) {
                page = route.children[0];
              }
              if (agenttype !== "Admin") {
                if (page.key.includes("masteragent") || 
                    page.path.includes("winning") ||
                    page.key === "announcement") {
                    return null;
                }
              }
              if (agenttype !== "Master Agent" && agenttype !== "Admin") {
                  if (page.key === "agents") {
                      return null;
                  }
              }
              if (agenttype !== "Agent" && agenttype !== "Master Agent") {
                  if (page.path.includes("player")) {
                      return null;
                  }
              } 
              return(
                <Route exact path={route.path} element={(agenttype!=="Admin" && route.path=="/dashboard")?route.component2:route.component} key={route.key}>
                  {route.children && route.children.map((child) => {
                    let checkChild = child;
                    if (child.children) {
                      checkChild = child.children[0];
                    }
                    if (agenttype === "Admin") {
                        if (checkChild.key === ("player-detail-report") ||
                            checkChild.key === "unit-balance-report") {
                            return null;
                        }
                    }
                    if (agenttype === "Master Agent") {
                        if (checkChild.key === "master-agent-detail-report" ||
                            checkChild.key === "gross-profit-report") {
                            return null;
                        }
                    }
                    if (agenttype === "Agent") {
                        if (checkChild.key === "master-agent-detail-report" ||
                            checkChild.key === "agent-detail-report" ||
                            checkChild.key === "comission-report" ||
                            checkChild.key === "gross-profit-report") {
                            return null;
                        }
                    }
                    return(
                      <Route path={child.path} element={child.component} key={child.key} >
                        {child.children && child.children.map((child2) => (
                            <Route path={child2.path} element={child2.component} key={child2.key} />
                        ))}
                      </Route>
                    )
                  })}
                </Route>
              )
            })}
            <Route exact path="/contact" element={<Contact />} key="contact" />
          </Route>
          <Route element={PublicRoutes(isAuth)} >
            <Route exact path="/login" element={<Login />} key="login" />
          </Route>
          <Route exact path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Suspense>
      <LoadingDialog open={loading}/>
      <Snackbar onClose={handleSnackBarClose} anchorOrigin={{vertical: "top", horizontal: "right"}} open={message!==null} autoHideDuration={5000}>
        <Alert severity={success?"success": "error"}>{message}</Alert>
      </Snackbar>
    </ThemeProvider>
  );
}