import { CircularProgress, Dialog } from "@mui/material";

export default function LoadingDialog({open}) {
    return (
        <Dialog
            open={open}
            PaperProps={{
                style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                },
            }}
            // onClose={handleCloseLoading}
            >
                <CircularProgress/>
            </Dialog>
    );
}