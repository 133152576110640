import { AppBar, Avatar, Box, IconButton, Toolbar, Typography, Stack } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import avatar from "../assets/images/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userActions } from "../store/actions";

export default function Navbar({drawerWidth, handleDrawerToggle}) {
    
    const agent = useSelector((state) => state.auth.agent);
    const dispatch = useDispatch();
    const { name, agenttype, unitbalance, agentid } = agent;

    useEffect(() => {
        dispatch(userActions.getDetail(agentid));
    }, []);

    return (
        <AppBar
        position="fixed"
        sx={{
            width: {
                sm: `calc(100% - ${drawerWidth}px)`
            },
            ml: {
                sm: `calc(100% - ${drawerWidth}px)`
            },
            backgroundColor: "#FBFBFB",
            boxShadow: 'none !important',
            borderBottom: '1px solid rgba(0,0,0,0.12)'
        }}>
            <Toolbar>
                <IconButton
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                    mr: 2,
                    display: {
                        sm: 'none',
                    },
                }}
                >
                    <MenuIcon/>
                </IconButton>
                
                { agenttype !== "Admin" && 
                    (<Typography sx={{ color: "rgba(0,0,0,0.8)", fontSize: "0.8rem" }}>Current balance : {unitbalance??"0"}</Typography>)
                }   

                <Box sx={{flexGrow: 1}}/>
                <Stack
                direction="column"
                alignItems="flex-end"
                justifyContent="center"
                spacing={0}
                sx={{ height: '100%', mr: 1 }}
                >
                    <Typography sx={{ color: "#000", fontSize: "0.7rem" }}>{name??"Loading"}</Typography>
                    <Typography sx={{ color: "rgba(0,0,0,0.5)", fontSize: "0.7rem" }}>{agenttype??"Loading"}</Typography>
                </Stack>
                <Avatar src={avatar} sx={{ border: '1px solid rgba(0,0,0,0.4)', mr: 2 }} />
            </Toolbar>
        </AppBar>
    );
}