const { agentConst } = require('../constants');

const initialState = {
    agents: [],
    masterAgents: [],
    players: [],
    agent: {},
    masterAgent: {},
    player: {},
    agentCount: 0,
    masterAgentCount: 0,
    playerCount: 0,
    currentAgentCount: 0,
    currentMasterAgentCount: 0,
    currentPlayerCount: 0,
    unitTopupHistory: [],
    unitWithdrawHistory: [],
    unitTopupHistoryCount: 0,
    unitWithdrawHistoryCount: 0,
    announcement: "",
    grossProfit2d: [],
    grossProfit2dCount: 0,
    grossProfit3d: [],
    grossProfit3dCount: 0,
    betReport2d: [],
    betReport2dCount: 0,
    betReport3d: [],
    betReport3dCount: 0,
    betReport2dDetail: [],
    betReport2dDetailCount: 0,
    betReport3dDetail: [],
    betReport3dDetailCount: 0,
};

export function agentManagement(state = initialState, action) {
    switch (action.type) {
        case agentConst.SET_ALL_AGENT_COUNT:
            return {
                ...state,
                agentCount: action.count
            };
        case agentConst.SET_ALL_MASTER_AGENT_COUNT:
            return {
                ...state,
                masterAgentCount: action.count
            };
        case agentConst.SET_ALL_PLAYER_COUNT:
            return {
                ...state,
                playerCount: action.count
            };
        case agentConst.SET_CURRENT_AGENT_COUNT:
            return {
                ...state,
                currentAgentCount: action.count
            };
        case agentConst.SET_CURRENT_MASTER_AGENT_COUNT:
            return {
                ...state,
                currentMasterAgentCount: action.count
            };
        case agentConst.SET_CURRENT_PLAYER_COUNT:
            return {
                ...state,
                currentPlayerCount: action.count
            };
        case agentConst.SET_AGENT: 
            return {
                ...state,
                agent: action.agent
            };
        case agentConst.SET_MASTER_AGENT:
            return {
                ...state,
                masterAgent: action.masterAgent
            };
        case agentConst.SET_PLAYER:
            return {
                ...state,
                player: action.player
            };
        case agentConst.SET_ALL_AGENTS:
            return {
                ...state,
                agents: action.agents
            };
        case agentConst.SET_ALL_MASTER_AGENTS:
            return {
                ...state,
                masterAgents: action.masterAgents
            };
        case agentConst.SET_ALL_PLAYERS:
            return {
                ...state,
                players: action.players
            };
        case agentConst.CREATE_AGENT:
            return {
                ...state,
                agentCount: state.agentCount++,
                currentAgentCount: state.currentAgentCount++,
                agents: [
                    action.agent,
                    ...state.agents
                ]
            };
        case agentConst.CREATE_MASTER_AGENT:
            return {
                ...state,
                masterAgentCount: state.masterAgentCount++,
                currentMasterAgentCount: state.currentMasterAgentCount++,
                masterAgents: [
                    action.masterAgent,
                    ...state.masterAgents
                ]
            };
        case agentConst.CREATE_PLAYER:
            return {
                ...state,
                playerCount: state.playerCount++,
                currentPlayerCount: state.currentPlayerCount++,
                players: [
                    action.player,
                    ...state.players
                ]
            };
        case agentConst.UPDATE_AGENT:
            return {
                ...state,
                agents: state.agents.map((agent) => agent.agentid == action.agent.agentid ? action.agent: agent),
            };
        case agentConst.UPDATE_MASTER_AGENT:
            return {
                ...state,
                masterAgents: state.masterAgents.map(agent => agent.agentid == action.agent.agentid ? action.agent: agent),
            };
        case agentConst.UPDATE_PLAYER:
            return {
                ...state,
                players: state.players.map((player) => player.playerid == action.player.playerid ? action.player: player),
            };
        case agentConst.SET_UNIT_TOPUP_HISTORY:
            return {
                ...state,
                unitTopupHistory: action.unitHistory,
                unitTopupHistoryCount: action.count,
            };
        case agentConst.SET_UNIT_WITHDRAW_HISTORY:
            return {
                ...state,
                unitWithdrawHistory: action.unitHistory,
                unitWithdrawHistoryCount: action.count,
            };
        case agentConst.SET_ANNOUNCEMENT:
            return {
                ...state,
                announcement: action.announcement
            }
        case agentConst.SET_2D_GROSS_PROFIT:
            return {
                ...state,
                grossProfit2d: action.grossProfit,
                grossProfit2dCount: action.count
            }
        case agentConst.SET_3D_GROSS_PROFIT:
            return {
                ...state,
                grossProfit3d: action.grossProfit,
                grossProfit3dCount: action.count
            }
        case agentConst.SET_2D_BET_REPORT:
            return {
                ...state,
                betReport2d: action.betReport,
                betReport2dCount: action.count
            }
        case agentConst.SET_3D_BET_REPORT:
            return {
                ...state,
                betReport3d: action.betReport,
                betReport3dCount: action.count
            }
        case agentConst.SET_2D_BET_REPORT_DETAIL:
            return {
                ...state,
                betReport2dDetail: action.betReportDetail,
                betReport2dDetailCount: action.count
            }
        case agentConst.SET_3D_BET_REPORT_DETAIL:
            return {
                ...state,
                betReport3dDetail: action.betReportDetail,
                betReport3dDetailCount: action.count
            }
        default:
            return state;
    }
}