import { apiService } from '../../services/api.services';
import { deleteToken, setToken } from '../../utils/token';
import { deleteAgent, setAgent } from "../../utils/agent";
import { authConst } from '../constants';
import { statusActions } from './status.action';

const setAuthUser = (agent, isAuth) => ({
    type: authConst.SET_AUTH_USER,
    agent,
    isAuth 
});

const login = credentials => {
    return async(dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("post", "agent/login", credentials);
            const { token, agent } = response;
            setToken(token);
            setAgent(JSON.stringify({...agent, status:  agent.status?"Activated": "Deactivated"}));
            apiService.setAuthHeader(token);
            dispatch(setAuthUser({...agent, status:  agent.status?"Activated": "Deactivated"}, true));
            dispatch(statusActions.setSuccess(true));
            dispatch(statusActions.setMessage("Logged in successfully!"));
        } catch(err) {
            console.log(err);
            deleteToken();
            deleteAgent();
            apiService.setAuthHeader(null);
            dispatch(setAuthUser({}, false));
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const updateAuth = (data) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            setAgent(JSON.stringify(data));
            dispatch(setAuthUser(data, true));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const getDetail = (agentid) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call('get', 'agent/get-agent-detail?agentid='+agentid);
            const {agent} = response;
            setAgent(JSON.stringify({...agent, status:  agent.status?"Activated": "Deactivated"}));
            dispatch(setAuthUser({...agent, status:  agent.status?"Activated": "Deactivated"}, true));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const logout = () => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        deleteToken();
        deleteAgent();
        apiService.setAuthHeader(null);
        dispatch(setAuthUser({}));
        dispatch(statusActions.setSuccess(true));
        dispatch(statusActions.setMessage("Logged out successfully"));
        dispatch(statusActions.setLoading());
    }
}

export const userActions = {
    login,
    logout,
    getDetail,
    updateAuth,
}