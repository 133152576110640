
const initialState = {
    currentBalance: 0,
    balanceHistory: [],
    transactionHistory: [],
    balanceHistoryCount: 0,
    transactionCount: 0,
}

export function unitManagement(state=initialState, action) {
    switch(action.type) {
        default:
            return state;
    }
}